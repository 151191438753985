import {Extension} from '@tiptap/core';
import {Plugin} from 'prosemirror-state';
import {Fragment, Slice} from 'prosemirror-model';

const isLinkedItem = (pastedData) => {
  const regex = /^inscope:\/\/link\/[a-zA-Z0-9-_]+$/;
  return regex.test(pastedData);
};

const extractItemId = (pastedData) => {
  const regex = /^inscope:\/\/link\/([a-zA-Z0-9-_]+)$/;
  const match = pastedData.match(regex);
  return match ? match[1] : null;
};

const addLinkedTable = (editor, itemId) => {
  const defaultTableData = [[]];
  const defaultTableDataJSON = JSON.stringify(defaultTableData);
  const defaultMergedCells = [];
  const defaultMergedCellsJSON = JSON.stringify(defaultMergedCells);

  const tableHTML = `<document-table is-linked='${true}' linked-item-id='${itemId}' table-data='${defaultTableDataJSON}' table-meta='{}' table-merged-cells='${defaultMergedCellsJSON}' table-size-multiplier='1'></document-table><p></p>`;

  editor
    .chain()
    .focus()
    .insertContent(tableHTML)
    .run();
};

const hasPageNodes = (slice) => {
  let containsPageNodes = false;
  slice.content.descendants((node) => {
    if (node.type.name === 'page') {
      containsPageNodes = true;
      return false;
    }
  });
  return containsPageNodes;
};

const extractContentFromPages = (slice) => {
  const pageContent = [];

  slice.content.forEach((node) => {
    if (node.type.name === 'page') {
      node.content.forEach((child) => {
        pageContent.push(child);
      });
    } else {
      pageContent.push(node);
    }
  });

  if (pageContent.length === 0) {
    return slice;
  }

  const fragment = Fragment.fromArray(pageContent);
  const openStart = Math.max(0, slice.openStart - 1);
  const openEnd = Math.max(0, slice.openEnd - 1);

  return new Slice(fragment, openStart, openEnd);
};

export const DocumentPasteHandler = Extension.create({
  name: 'documentPasteHandler',

  addProseMirrorPlugins () {
    return [
      new Plugin({
        props: {
          handlePaste: (view, event, slice) => {
            const pastedText = event.clipboardData.getData('text/plain');

            if (isLinkedItem(pastedText)) {
              event.preventDefault();
              const itemId = extractItemId(pastedText);
              addLinkedTable(this.editor, itemId);
              return true;
            }

            try {
              if (hasPageNodes(slice)) {
                event.preventDefault();
                const newSlice = extractContentFromPages(slice);
                view.dispatch(view.state.tr.replaceSelection(newSlice));
                return true;
              }
            } catch (error) {
              console.error('Error during page node paste handling:', error);
              event.preventDefault();
              return true;
            }

            return false;
          },
        },
      }),
    ];
  },
});
