import SingleTopBorderIcon from '../../../assets/single-top-border.svg';
import DoubleTopBorderIcon from '../../../assets/double-top-border.svg';
import SingleBottomBorderIcon from '../../../assets/single-bottom-border.svg';
import DoubleBottomBorderIcon from '../../../assets/double-bottom-border.svg';
import ArrowLeftIcon from '../../../assets/arrow-left.svg';
import ArrowRightIcon from '../../../assets/arrow-right.svg';
import ArrowUpIcon from '../../../assets/arrow-up.svg';
import ArrowDownIcon from '../../../assets/arrow-down.svg';
import TrashIcon from '../../../assets/trash.svg';
import MergeIcon from '../../../assets/merge.svg';
import UnMergeIcon from '../../../assets/un-merge.svg';
import EditIcon from '../../../assets/edit.svg';
import DollarIcon from '../../../assets/dollar-sign.svg';
import PercentIcon from '../../../assets/percent-sign.svg';
import NoneIcon from '../../../assets/none.svg';

// Util
import {menuItemDisplay} from './util';

const updateCellsInRange = (hotInstance, ranges, properties) => {
  ranges.forEach(range => {
    for (let row = range.from.row; row <= range.to.row; row++) {
      const isLeftToRight = range.from.col <= range.to.col;

      if (isLeftToRight) {
        // Left to right selection
        for (let col = range.from.col; col <= range.to.col; col++) {
          const cellMeta = hotInstance.getCellMeta(row, col);
          if (cellMeta.type !== 'paddingCell') {
            hotInstance.updateCellProperties(row, col, properties);
          }
        }
      } else {
        // Right to left selection
        for (let col = range.from.col; col >= range.to.col; col--) {
          const cellMeta = hotInstance.getCellMeta(row, col);
          if (cellMeta.type !== 'paddingCell') {
            hotInstance.updateCellProperties(row, col, properties);
          }
        }
      }
    }
  });
};

const clearCellFormattingInRange = (hotInstance, ranges) => {
  ranges.forEach(range => {
    for (let row = range.from.row; row <= range.to.row; row++) {
      const isLeftToRight = range.from.col <= range.to.col;

      if (isLeftToRight) {
        // Left to right selection
        for (let col = range.from.col; col <= range.to.col; col++) {
          if (row <= 1) {
            hotInstance.updateCellProperties(row, col, {
              cleared: true,
              format: null,
              doubleBorderTop: false,
              doubleBorderBottom: false,
              singleBorderTop: false,
              singleBorderBottom: false,
            });
          } else {
            hotInstance.updateCellProperties(row, col, {
              format: null,
              doubleBorderTop: false,
              doubleBorderBottom: false,
              singleBorderTop: false,
              singleBorderBottom: false,
            });
          }
        }
      } else {
        // Right to left selection
        for (let col = range.from.col; col >= range.to.col; col--) {
          if (row <= 1) {
            hotInstance.updateCellProperties(row, col, {cleared: true});
          } else {
            hotInstance.updateCellProperties(row, col, {
              format: null,
              doubleBorderTop: false,
              doubleBorderBottom: false,
              singleBorderTop: false,
              singleBorderBottom: false,
            });
          }
        }
      }
    }
    hotInstance.render();
  });
};

export const ContextMenu = {
  items: {
    col_left: {
      name () { return menuItemDisplay('Insert column left', ArrowLeftIcon) },
      hidden () {
        // do not let user add column left of label column
        return this.getSelectedLast()[1] === 0 || this.getSelectedLast()[1] === 1;
      },
      disabled () { return this.isLinked },
      callback (key, selection, clickEvent) {
        const selectedRange = this.getSelectedRange();
        if (selectedRange) {
          const firstCol = selectedRange[0].from.col;
          this.alter('insert_col_start', firstCol, 2);
        }
      },
    },
    col_right: {
      name () { return menuItemDisplay('Insert column right', ArrowRightIcon) },
      disabled () { return this.isLinked },
      callback (key, selection, clickEvent) {
        const selectedRange = this.getSelectedRange();
        if (selectedRange) {
          const lastCol = selectedRange[0].to.col;
          this.alter('insert_col_end', lastCol + 1, 2);
        }
      },
    },
    row_above: {
      name () { return menuItemDisplay('Insert row above', ArrowUpIcon) },
      disabled () { return this.isLinked },
      hidden () {
        // Hide this option for header rows
        return this.getSelectedLast()[0] <= 1;
      },
    },
    header_row_above: {
      name () { return menuItemDisplay('Insert row above', ArrowUpIcon) },
      disabled () { return this.isLinked },
      hidden () {
        return this.getSelectedLast()[0] !== 1;
      },
      callback (key, selection, clickEvent) {
        let colIndex = this.getSelectedLast()[1];

        const mergeCellsPlugin = this.getPlugin('mergeCells');
        const mergedCell = mergeCellsPlugin.mergedCellsCollection.get(0, colIndex);

        if (mergedCell) {
          colIndex = mergedCell.col;
        }

        this.selectCell(0, colIndex);
        const range = {from: {row: 0, col: colIndex}, to: {row: 0, col: colIndex}};
        updateCellsInRange(this, range, {singleBorderBottom: true});
      },
    },
    row_below: {
      name () { return menuItemDisplay('Insert row below', ArrowDownIcon) },
      disabled () { return this.isLinked },
      hidden () {
        // Hide this option for the very first header row
        return this.getSelectedLast()[0] === 0;
      },
    },
    cell_format: {
      name () { return menuItemDisplay('Cell format', EditIcon) },
      hidden () {
        const [row, col] = this.getSelectedLast();
        const cellMeta = this.getCellMeta(row, col);
        return cellMeta.type !== 'valueCell' && cellMeta.type !== 'totalCell';
      },
      submenu: {
        items: [
          {
            key: 'cell_format:none',
            name () { return menuItemDisplay('None', NoneIcon) },
            callback (key, selection, clickEvent) {
              const range = this.getSelectedRange();
              updateCellsInRange(this, range, {format: null});
            },
          },
          {
            key: 'cell_format:dollar',
            name () { return menuItemDisplay('Dollar', DollarIcon) },
            callback (key, selection, clickEvent) {
              const range = this.getSelectedRange();
              updateCellsInRange(this, range, {format: 'dollar'});
            },
          },
          {
            key: 'cell_format:percent',
            name () { return menuItemDisplay('Percent', PercentIcon) },
            callback (key, selection, clickEvent) {
              const range = this.getSelectedRange();
              updateCellsInRange(this, range, {format: 'percent'});
            },
          },
        ],
      },
    },
    top_border: {
      name () { return menuItemDisplay('Top border', SingleTopBorderIcon) },
      submenu: {
        items: [
          {
            key: 'top_border:none',
            name () { return menuItemDisplay('None', NoneIcon) },
            callback (key, selection, clickEvent) {
              const range = this.getSelectedRange();
              updateCellsInRange(this, range, {doubleBorderTop: false, singleBorderTop: false});
            },
          },
          {
            key: 'top_border:single',
            name () { return menuItemDisplay('Single', SingleTopBorderIcon) },
            callback (key, selection, clickEvent) {
              const range = this.getSelectedRange();
              updateCellsInRange(this, range, {doubleBorderTop: false, singleBorderTop: true});
            },
          },
          {
            key: 'top_border:double',
            name () { return menuItemDisplay('Double', DoubleTopBorderIcon) },
            callback (key, selection, clickEvent) {
              const range = this.getSelectedRange();
              updateCellsInRange(this, range, {doubleBorderTop: true, singleBorderTop: false});
            },
          },
        ],
      },
    },
    bottom_border: {
      name () { return menuItemDisplay('Bottom border', SingleBottomBorderIcon) },
      submenu: {
        items: [
          {
            key: 'bottom_border:none',
            name () { return menuItemDisplay('None', NoneIcon) },
            callback (key, selection, clickEvent) {
              const range = this.getSelectedRange();
              updateCellsInRange(this, range, {doubleBorderBottom: false, singleBorderBottom: false});
            },
          },
          {
            key: 'bottom_border:single',
            name () { return menuItemDisplay('Single', SingleBottomBorderIcon) },
            callback (key, selection, clickEvent) {
              const range = this.getSelectedRange();
              updateCellsInRange(this, range, {doubleBorderBottom: false, singleBorderBottom: true});
            },
          },
          {
            key: 'bottom_border:double',
            name () { return menuItemDisplay('Double', DoubleBottomBorderIcon) },
            callback (key, selection, clickEvent) {
              const range = this.getSelectedRange();
              updateCellsInRange(this, range, {doubleBorderBottom: true, singleBorderBottom: false});
            },
          },
        ],
      },
    },
    mergeCells: {
      name () {
        const sel = this.getSelectedLast();

        if (sel) {
          const info = this.getPlugin('mergeCells').mergedCellsCollection.get(sel[0], sel[1]);

          if (info.row === sel[0] && info.col === sel[1] &&
              info.row + info.rowspan - 1 === sel[2] && info.col + info.colspan - 1 === sel[3]) {
            return menuItemDisplay('Unmerge cells', UnMergeIcon);
          }
        }

        return menuItemDisplay('Merge cells', MergeIcon);
      },
      disabled () { return this.isLinked },
    },
    remove_row: {
      name () {
        const selection = this.getSelected();
        let pluralForm = 0;

        if (selection) {
          if (selection.length > 1) {
            pluralForm = 1;
          } else {
            const [fromRow, , toRow] = selection[0];

            if (fromRow - toRow !== 0) {
              pluralForm = 1;
            }
          }
        }

        if (pluralForm !== 0) {
          return menuItemDisplay('Delete rows', TrashIcon);
        }

        return menuItemDisplay('Delete row', TrashIcon);
      },
      disabled () { return this.isLinked },
      hidden () {
        // Hide this option for the very first header row
        return this.getSelectedLast()[0] <= 1;
      },
    },
    remove_col: {
      name () { return menuItemDisplay('Delete column', TrashIcon) },
      disabled () { return this.isLinked },
      hidden () {
        // do not let user delete the label column or first value column
        return this.getSelectedLast()[1] === 0 ||
          this.getSelectedLast()[1] === 1;
      },
      callback (key, selection, clickEvent) {
        const selectedRange = this.getSelectedRange();
        if (selectedRange) {
          const firstCol = selectedRange[0].from.col;
          const adjustedFirstCol = Math.max(0, firstCol - 1);
          this.alter('remove_col', adjustedFirstCol, 2);
        }
      },
    },
    clear_formatting: {
      name () { return menuItemDisplay('Clear formatting', NoneIcon) },
      callback (key, selection, clickEvent) {
        const range = this.getSelectedRange();
        clearCellFormattingInRange(this, range);
      },
    },
  },
}
