import React from 'react';
import {
  Box,
  Drawer,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';

import DocumentThreads from './DocumentThreads';

import {BLACK_100, WHITE_100, COMMENT_DRAWER_WIDTH} from '../../theme';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';

const HEADER_HEIGHT = '2rem';

const styles = {
  header: {
    suggestionsLabel: {
      color: BLACK_100,
      fontSize: '1.25rem',
      fontWeight: 700,
      lineHeight: '1.5rem',
    },
  },
}

const CommentDrawerHeader = ({onClose}) => {
  return (
    <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} height={HEADER_HEIGHT}>
      <Stack direction={'row'} my={'0.25rem'} spacing={'0.5rem'} alignItems={'center'}>
        <Typography sx={styles.header.suggestionsLabel}>Comments</Typography>
      </Stack>
      <IconButton onClick={onClose}>
        <CloseIcon />
      </IconButton>
    </Stack>
  )
}

CommentDrawerHeader.propTypes = {
  onClose: PropTypes.func.isRequired,
};

const CommentDrawer = React.memo(({editor, readOnlySave, open, onClose, readOnly}) => {
  return (
    <Drawer
      anchor={'right'}
      open={open}
      variant={'persistent'}
      onClose={onClose}
      sx={{zIndex: 998}}
      PaperProps={{
        sx: {
          marginTop: '6.5rem',
          paddingBottom: '3rem',
          background: WHITE_100,
          borderLeft: '1px solid #DDE0DE',
          height: 'calc(100% - 6rem)',
        },
      }}
    >
      <Stack
        direction={'column'}
        sx={{
          width: COMMENT_DRAWER_WIDTH,
          paddingY: '1rem',
          paddingX: '1.25rem',
        }}>
        <CommentDrawerHeader onClose={onClose}/>

        <Box display={'flex'} flex={1} mt={2}>
          <DocumentThreads editor={editor} readOnlySave={readOnlySave} />
        </Box>
      </Stack>
    </Drawer>
  )
});

CommentDrawer.propTypes = {
  editor: PropTypes.object.isRequired,
  readOnlySave: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  readOnly: PropTypes.bool.isRequired,
};

CommentDrawer.displayName = 'CommentDrawer';

export default CommentDrawer;
