import React, {useState, useEffect, useRef, useCallback} from 'react'
import {connect, useDispatch, useSelector} from 'react-redux';
import {withRequiredAuthInfo} from '@propelauth/react';
import PropTypes from 'prop-types';

// Custom Components
import Drawer from '../../components/copilot/Drawer';
import DocumentEditor from './DocumentEditor';

// PDF
import {useReactToPrint} from 'react-to-print';

// MUI
import {
  Box,
  Snackbar,
  Stack,
  Typography,
} from '@mui/material';

// Icons
import CheckIcon from '@mui/icons-material/Check';

// Stylesheets
import './DocumentStyles.css'
import '@liveblocks/react-comments/styles.css';

// Utils
import {useHighlightEventListener} from '../../components/report-workspace/comment-utils';
import {
  useGetWorkspaceSignedUrlQuery,
  useCreateWorkspaceSignedUrlMutation,
} from '../../data/api';
import axios from 'axios';
import {COPILOT_DRAWER_WIDTH, GREY_70} from '../../App'
import {BLACK_100, WHITE_100} from '../../theme';
import {hideActionSuccess, hideReviewComplete, hideActionError, setDocumentId as copilotSetDocumentId, reset as copilotReset} from '../../data/copilot';
import _ from 'lodash';
import {useParams} from 'react-router-dom';
import {withLDConsumer} from 'launchdarkly-react-client-sdk';
import {useGetDocumentQuery, useMarkDocumentSavedMutation} from '../../data/documentWorkspaceApi';
import Error from '../../components/Error';
import NoData from '../../components/NoData';
import Loading from '../../components/Loading';
import {sleep} from '../../util';

const styles = {
  review: {
    container: {
      bgcolor: BLACK_100,
      borderRadius: '0.5rem',
    },
    header: {
      color: WHITE_100,
      fontSize: '0.875rem',
      fontWeight: 700,
      lineHeight: '1.25rem',
    },
    label: {
      color: WHITE_100,
      fontSize: '0.75rem',
      fontWeight: 400,
      lineHeight: '1.25rem',
    },
    boldLabel: {
      color: WHITE_100,
      fontSize: '0.75rem',
      fontWeight: 700,
      lineHeight: '1.25rem',
    },
  },
}

const putFileToS3 = async (presignedUrl, fileData) => {
  try {
    await axios.put(presignedUrl, fileData, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  } catch (error) {
    console.error('Error uploading file to S3:', error);
  }
};

const getFileFromS3 = async (presignedUrl) => {
  try {
    const response = await axios.get(presignedUrl);
    return response.data;
  } catch (error) {
    console.error('Error fetching document from S3:', error);
    throw error;
  }
};

const Document = ({accessHelper, flags}) => {
  const {documentId} = useParams();
  const {currentOrg} = useSelector((state) => state.session);
  const {documentId: copilotDocumentId} = useSelector((state) => state.copilot);
  const {copilotActionSuccess, copilotReviewComplete, copilotActionMessage, copilotActionError, copilotErrorMessage} = useSelector((state) => state.copilot);
  const dispatch = useDispatch();
  const canEdit = accessHelper.hasPermission(currentOrg.orgId, 'can_edit_reports');

  const [createWorkspaceSignedUrl] = useCreateWorkspaceSignedUrlMutation();
  const {data, isFetching, isError, isSuccess, refetch} = useGetWorkspaceSignedUrlQuery({filename: 'report.json'}, {skip: flags.multipleDocuments, refetchOnMountOrArgChange: true});
  const {data: documentData, isFetching: documentIsFetching, isError: documentIsError, error: documentError, isSuccess: documentIsSuccess, refetch: documentRefetch} = useGetDocumentQuery({documentId}, {skip: !flags.multipleDocuments || _.isNil(documentId) || _.isNil(currentOrg), refetchOnMountOrArgChange: true});
  const [markDocumentSaved, {isError: documentSaveIsError, error: documentSaveError, reset: documentSaveReset}] = useMarkDocumentSavedMutation();

  const [isSaving, setIsSaving] = useState(false);
  const [saveFileUrl, setSaveFileUrl] = useState();
  const [isLoadingDocument, setIsLoadingDocument] = useState(true);

  const [copilotOpen, setCopilotOpen] = useState(false);
  const copilot = useSelector(state => state.copilot);

  const [commentsOpen, setCommentsOpen] = useState(false);
  const [content, setContent] = useState('<p></p>');

  const [isPrinting, setIsPrinting] = useState(false);
  const [isPrintContentReady, setIsPrintContentReady] = useState(false);
  const [isPreparingPrint, setIsPreparingPrint] = useState(false);
  const contentToPrintRef = useRef(null);
  const editorRef = useRef(null);

  const _fetchDocument = async (signedUrl) => {
    if (signedUrl) {
      try {
        const fileData = await getFileFromS3(signedUrl);
        await deserializeDocument(fileData);
      } catch (error) {
        if (_.get(error, 'response.status') === 403) {
          if (!flags.multipleDocuments) {
            await refetch();
          } else {
            await documentRefetch();
          }
        } else {
          console.error('Error fetching or deserializing document:', error);
          setIsLoadingDocument(false);
        }
      }
    }
  };

  useEffect(() => {
    if (!documentIsFetching) {
      if (documentIsError && flags.multipleDocuments) {
        if (documentError.status !== 401) {
          setIsLoadingDocument(false);
        } else {
          sleep(500).then(() => {
            documentRefetch();
          })
        }
      }
      if (documentIsSuccess) {
        setSaveFileUrl(documentData.putSignedUrl);
        _fetchDocument(documentData.getSignedUrl);
        if (documentId !== copilotDocumentId) {
          dispatch(copilotReset());
          dispatch(copilotSetDocumentId({documentId}));
        }
      }
    }
  }, [documentIsSuccess, documentIsError, documentIsFetching]);

  useEffect(() => {
    if (documentSaveIsError) {
      console.error('Error marking document saved', {error: documentSaveError});
    }
  }, [documentSaveIsError]);

  const serializeDocument = useCallback((editor) => {
    const editorJSON = editor?.getJSON();

    return {
      json: editorJSON,
      text: accumulateTextByPage(editorJSON),
      tables: accumulateTablesByPage(editorJSON),
    };
  }, []);

  const saveDocument = useCallback(async (editor, retryCount = 3) => {
    setIsSaving(true);

    if (!saveFileUrl && flags.multipleDocuments) {
      console.error('Error saving file, no url provided');
      return;
    }

    try {
      const documentData = serializeDocument(editor);
      let signedUrl;

      if (!saveFileUrl && !flags.multipleDocuments) {
        const filename = 'report.json';
        const {data: {signedUrl: newSignedUrl}} = await createWorkspaceSignedUrl(filename);
        signedUrl = newSignedUrl;
        setSaveFileUrl(newSignedUrl);
      }

      await putFileToS3(saveFileUrl || signedUrl, JSON.stringify(documentData));
      if (flags.multipleDocuments) {
        await markDocumentSaved({documentId});
        documentSaveReset();
      }
    } catch (error) {
      console.error('Error saving document:', error);

      if (retryCount > 0) {
        setTimeout(() => saveDocument(editor, retryCount - 1), 2000);
      } else {
        console.error('Maximum retries reached, unable to save document.');
        setIsSaving(false);
        alert('Something went wrong saving your document. Please refresh your page and try again.');
      }
    } finally {
      setIsSaving(false);
    }
  }, [saveFileUrl, createWorkspaceSignedUrl, serializeDocument]);

  const deserializeDocument = async (fileData) => {
    if (!(typeof fileData === 'object' && 'json' in fileData && 'text' in fileData)) {
      setIsLoadingDocument(false);
    }

    const {json: editorJSON} = fileData;
    setContent(editorJSON);
    setIsLoadingDocument(false);
  };

  const accumulateTextByPage = (json) => {
    const result = [];

    const extractText = (content) => {
      let text = '';
      if (Array.isArray(content)) {
        for (const node of content) {
          if (node.type === 'text') {
            text += node.text;
          } else if (node.content) {
            text += extractText(node.content);
          }
        }
      }
      return text;
    }

    if (json && Array.isArray(json.content)) {
      for (const page of json.content) {
        if (page.type === 'page') {
          const pageText = extractText(page.content);
          result.push({page: page.attrs.pageNumber, text: pageText});
        }
      }
    }

    return result;
  };

  const accumulateTablesByPage = (json) => {
    const result = [];

    const extractTables = (content) => {
      const tables = [];
      if (Array.isArray(content)) {
        for (const node of content) {
          if (node.type === 'table') {
            const id = node.attrs.id;
            const tableData = JSON.parse(node.attrs['table-data'].replace(/\\'/g, "'"));
            const tableMeta = JSON.parse(node.attrs['table-meta']);
            tables.push({id, tableData, tableMeta});
          }
        }
      }
      return tables;
    };

    if (json && Array.isArray(json.content)) {
      for (const page of json.content) {
        if (page.type === 'page') {
          const tables = extractTables(page.content);
          result.push({page: page.attrs.pageNumber, tables});
        }
      }
    }

    return result;
  };

  useEffect(() => {
    if (isSuccess && !isFetching && !isError && !flags.multipleDocuments) {
      _fetchDocument(data?.signedUrl);
    }
  }, [data, isFetching, isError, isSuccess]);

  useHighlightEventListener((highlightId, isClick) => {
    if (isClick) setCommentsOpen(true);
  });

  const openCopilot = useCallback(() => {
    setCommentsOpen(false);
    setCopilotOpen(!copilotOpen);
  }, [copilotOpen]);

  const closeCopilot = () => {
    setCopilotOpen(false);
  };

  const toggleComments = useCallback(() => {
    setCopilotOpen(false);
    setCommentsOpen(!commentsOpen);
  }, [commentsOpen]);

  const openComments = useCallback(() => {
    setCopilotOpen(false);
    setCommentsOpen(true);
  }, [commentsOpen]);

  const closeComments = useCallback(() => {
    setCommentsOpen(false);
  }, []);

  const enterPrintMode = () => {
    setIsPreparingPrint(true);
    // since we're forcing a render we need to ensure we have the latest content applied
    const latestContent = editorRef.current ? editorRef.current.getJSON() : content;
    setContent(latestContent);
    setIsPrinting(true);
  };

  const exitPrintMode = () => {
    setIsPrinting(false);
    setIsPreparingPrint(false);
    setIsPrintContentReady(false);
  };

  const handlePrint = useReactToPrint({
    documentTitle: !flags.multipleDocuments ? `${currentOrg.orgName} Annual Report` : _.get(documentData, 'record.name'),
    removeAfterPrint: true,
    content: () => contentToPrintRef.current,
    onAfterPrint: exitPrintMode,
  });

  const onPrintContentReady = useCallback(() => {
    setIsPrintContentReady(true);
  }, []);

  useEffect(() => {
    if (isPrinting && isPrintContentReady) {
      setIsPreparingPrint(false);
      handlePrint();
    }
  }, [isPrinting, isPrintContentReady, handlePrint]);

  if (flags.multipleDocuments && _.isNil(documentId)) {
    // Redirect to documents page if documentId is not provided. Should never happen.
    this.props.history.replace('/documents');
  }

  const _getErrorState = () => {
    switch (documentError.status) {
      case 404:
        return <NoData hidden={!documentIsError} screen={'document'} />
      default:
        return <Error hasError={documentIsError} message={'There was an error loading your document. Please try again later.'} />
    }
  }

  return (
    <Stack direction='column' width='100%' height='100vh' bgcolor={GREY_70}>
      <Loading
        loading={isLoadingDocument || isPreparingPrint}
        message={isLoadingDocument ? 'Loading report' : 'Preparing PDF for print'}
        loadingProps={{size: '46'}}
        containerProps={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          bgcolor: GREY_70,
          zIndex: 1000,
          pt: '45px',
        }}
      />

      {!isLoadingDocument && documentIsError && (
        <Box display={'flex'} flex={1} height={'100%'} alignItems={'center'}>
          {_getErrorState()}
        </Box>
      )}

      {!isLoadingDocument && !documentIsError &&
        <React.Fragment>
          <DocumentEditor
            key={isPrinting}
            editorRef={editorRef}
            canEdit={canEdit}
            content={content}
            contentToPrintRef={contentToPrintRef}
            commentsOpen={commentsOpen}
            copilotOpen={copilotOpen}
            closeComments={closeComments}
            isPrinting={isPrinting}
            onPrintContentReady={onPrintContentReady}
            openComments={openComments}
            toggleComments={toggleComments}
            openCopilot={openCopilot}
            saveDocument={saveDocument}
            isSaving={isSaving}
            enterPrintMode={enterPrintMode}
          />
          <Drawer
            onClose={closeCopilot}
            open={copilotOpen}
          />
        </React.Fragment>
      }

      <Snackbar
        open={copilotActionSuccess}
        autoHideDuration={5000}
        onClose={() => dispatch(hideActionSuccess())}
        anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
        sx={{paddingRight: copilotOpen ? COPILOT_DRAWER_WIDTH : 0}}
      >
        <Stack direction={'row'} sx={{backgroundColor: WHITE_100, borderRadius: '0.5rem', boxShadow: '0px 1px 5px 0px rgba(11, 14, 12, 0.15);'}} width={'16.25rem'} height={'3rem'} paddingX={'1rem'} alignItems={'center'}>
          <Box marginRight={'0.75rem'} display={'flex'} justifyContent={'center'} alignItems={'center'} padding={'0.25rem'} height={'1.25rem'} width={'1.25rem'} borderRadius={'50%'} sx={{background: 'linear-gradient(0deg, #33B980 0%, #33B980 100%), linear-gradient(180deg, rgba(49, 197, 125, 0.70) 0%, #3B768B 100%), #F1F4F2;'}}>
            <CheckIcon fontSize={'12px'} sx={{color: WHITE_100}}/>
          </Box>
          <Typography variant='body1'>{!_.isNil(copilotActionMessage) ? copilotActionMessage : 'Item resolved and closed'}</Typography>
        </Stack>
      </Snackbar>
      <Snackbar
        open={copilotActionError}
        autoHideDuration={5000}
        onClose={() => dispatch(hideActionError())}
        anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
        sx={{paddingRight: copilotOpen ? COPILOT_DRAWER_WIDTH : 0}}
      >
        <Stack direction={'row'} sx={{backgroundColor: WHITE_100, borderRadius: '0.5rem', boxShadow: '0px 1px 5px 0px rgba(11, 14, 12, 0.15);'}} width={'16.25rem'} height={'3rem'} paddingX={'1rem'} alignItems={'center'}>
          <Box marginRight={'0.75rem'} display={'flex'} justifyContent={'center'} alignItems={'center'} padding={'0.25rem'} height={'1.25rem'} width={'1.25rem'} borderRadius={'50%'} sx={{background: 'linear-gradient(0deg, #33B980 0%, #33B980 100%), linear-gradient(180deg, rgba(49, 197, 125, 0.70) 0%, #3B768B 100%), #F1F4F2;'}}>
            <CheckIcon fontSize={'12px'} sx={{color: WHITE_100}}/>
          </Box>
          <Typography variant='body1'>{!_.isNil(copilotErrorMessage) ? copilotErrorMessage : 'There was an error processing your request. Please try again later.'}</Typography>
        </Stack>
      </Snackbar>
      <Snackbar
        open={copilotReviewComplete}
        autoHideDuration={5000}
        onClose={() => dispatch(hideReviewComplete())}
        anchorOrigin={{vertical: 'top', horizontal: 'right'}}
        sx={{paddingTop: '8rem'}}
      >
        <Stack direction={'column'} spacing={'0.125rem'}>
          <Typography style={styles.review.header}>Review complete!</Typography>
          <Stack direction={'row'}><Typography style={styles.review.label}>You have&nbsp;</Typography><Typography style={styles.review.boldLabel}>{copilot.numOpenItems} items</Typography><Typography style={styles.review.label}>&nbsp;to resolve.</Typography></Stack>
        </Stack>
      </Snackbar>
    </Stack>
  );
}

Document.propTypes = {
  session: PropTypes.object.isRequired,
  accessHelper: PropTypes.shape({
    hasPermission: PropTypes.func,
  }).isRequired,
  flags: PropTypes.shape({
    multipleDocuments: PropTypes.bool.isRequired,
  }),
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
  }
}

export default withLDConsumer()(connect(mapStateToProps)(withRequiredAuthInfo(Document)));
