import {Fragment, Slice} from '@tiptap/pm/model';
import {getAbsentHtml, getBreakPos, getDomHeight} from './Core';
import {getNodeType} from '@tiptap/core';
import {ReplaceStep} from '@tiptap/pm/transform';
import {getId} from './page-utils';

// This equates to 0 margin between paragraphs (see report-workspace/DocumentStyles.css)
// If margin is added, this must increase as well by the same amount
const PARAGRAPH_DEFAULT_HEIGHT = 18;

const calculateListHeight = (splitContext, node, pos, parent, dom) => {
  const pHeight = getDomHeight(dom);
  if (splitContext.isOverflow(pHeight)) {
    splitContext.setBoundary(pos, splitContext.splitResolve(pos).length - 1);
    return true;
  }
  splitContext.addHeight(pHeight);
  return false;
};

let count = 1;

const calculateListItemHeight = (splitContext, node, pos, parent, dom) => {
  const chunks = splitContext.splitResolve(pos);
  if (splitContext.isOverflow(0)) {
    if (count > 1) {
      count = 1;
      splitContext.setBoundary(chunks[chunks.length - 2][2], chunks.length - 2);
    } else {
      splitContext.setBoundary(pos, chunks.length - 1);
      count += 1;
    }
    return false;
  }
  const pHeight = getDomHeight(dom);
  if (splitContext.isOverflow(pHeight)) {
    if (pHeight > splitContext.getHeight()) {
      splitContext.addHeight(pHeight);
      return false;
    }

    if (parent?.firstChild === node) {
      splitContext.setBoundary(chunks[chunks.length - 2][2], chunks.length - 2);
    } else {
      splitContext.setBoundary(pos, chunks.length - 1);
    }
  } else {
    splitContext.addHeight(pHeight);
  }
  return false;
};

const calculateTableHeight = (splitContext, node, pos, parent, dom) => {
  const tableHeight = JSON.parse(node.attrs['table-height']);
  if (splitContext.isOverflow(tableHeight)) {
    splitContext.setBoundary(pos, splitContext.splitResolve(pos).length - 1);
    return true;
  }
  splitContext.addHeight(tableHeight);
  return false;
};

const calculateHorizontalRuleHeight = (splitContext, node, pos, parent, dom) => {
  const hrHeight = getDomHeight(dom);
  if (splitContext.isOverflow(hrHeight)) {
    const chunks = splitContext.splitResolve(pos);
    splitContext.setBoundary(pos, chunks.length - 1);
    return true;
  }
  splitContext.addHeight(hrHeight);
  return false;
};

export const defaultNodesComputed = {
  orderedList: calculateListHeight,
  bulletList: calculateListHeight,
  listItem: calculateListItemHeight,
  table: calculateTableHeight,
  horizontalRule: calculateHorizontalRuleHeight,
  heading: (splitContext, node, pos, parent, dom) => {
    const pHeight = getDomHeight(dom);
    if (splitContext.isOverflow(pHeight)) {
      const chunks = splitContext.splitResolve(pos);
      splitContext.setBoundary(pos, chunks.length - 1);
    }
    splitContext.addHeight(pHeight);
    return false;
  },
  paragraph: (splitContext, node, pos, parent, dom) => {
    const pHeight = node.childCount > 0 ? getDomHeight(dom) : splitContext.getDefaultHeight();
    if (!splitContext.isOverflow(pHeight)) {
      splitContext.addHeight(pHeight);
      return false;
    }
    const chunks = splitContext.splitResolve(pos);
    if (pHeight > splitContext.getDefaultHeight()) {
      const point = getBreakPos(node, dom, splitContext);
      if (point) {
        splitContext.setBoundary(pos + point, chunks.length);
        return false;
      }
    }
    if (parent?.firstChild === node) {
      splitContext.setBoundary(chunks[chunks.length - 2][2], chunks.length - 2);
      return false;
    }
    splitContext.setBoundary(pos, chunks.length - 1);
    return false;
  },
  page: (splitContext, node, pos, parent, dom) => {
    return node === splitContext.lastPage();
  },
};

export class SplitContext {
  #doc;
  #accumulatedHeight = 0;
  #pageBoundary = null;
  #height = 0;
  #paragraphDefaultHeight = 0;
  attributes = {};

  constructor (doc, height, paragraphDefaultHeight) {
    this.#doc = doc;
    this.#height = height;
    this.#paragraphDefaultHeight = paragraphDefaultHeight;
  }

  getHeight () {
    return this.#height;
  }

  getDefaultHeight () {
    return this.#paragraphDefaultHeight;
  }

  isOverflow (height) {
    return this.#accumulatedHeight + height > this.#height;
  }

  addHeight (height) {
    this.#accumulatedHeight += height;
  }

  setBoundary (pos, depth) {
    this.#pageBoundary = {
      pos,
      depth,
    };
  }

  pageBoundary () {
    return this.#pageBoundary;
  }

  splitResolve (pos) {
    const array = this.#doc.resolve(pos).path;
    const chunks = [];
    if (array.length <= 3) return array;
    const size = 3;
    for (let i = 0; i < array.length; i += size) {
      chunks.push(array.slice(i, i + size));
    }
    return chunks;
  }

  lastPage () {
    return this.#doc.lastChild;
  }
}

export class PageComputedContext {
  nodesComputed;
  state;
  tr;
  pageState;
  editor;

  constructor (editor, nodesComputed, pageState, state) {
    this.editor = editor;
    this.nodesComputed = nodesComputed;
    this.tr = state.tr;
    this.state = state;
    this.pageState = pageState;
  }

  run () {
    const {selection, doc} = this.state;
    const {inserting, deleting, checkNode, splitPage} = this.pageState;
    if (splitPage) return this.initComputed();
    if (checkNode) return this.checkNodeAndFix();
    if (!inserting && deleting && selection.$head.node(1) === doc.lastChild) return this.tr;
    if (inserting || deleting) {
      this.computed();
      window.checkNode = true;
    }
    return this.tr;
  }

  computed () {
    const tr = this.tr;
    const {selection} = this.state;
    const currNumber = tr.doc.content.findIndex(selection.head).index + 1;
    if (tr.doc.childCount > 1 && tr.doc.content.childCount !== currNumber) {
      this.mergeDocument();
    }
    this.splitDocument();
    return this.tr;
  }

  initComputed () {
    this.mergeDefaultDocument(1);
    this.splitDocument();
    return this.tr;
  }

  splitDocument () {
    const {schema} = this.state;
    while (true) {
      const splitInfo = this.getNodeHeight();
      if (!splitInfo) {
        break;
      }
      const type = getNodeType('page', schema);
      this.splitPage({
        pos: splitInfo.pos,
        depth: splitInfo.depth,
        typesAfter: [{type}],
        schema,
      });
    }
  }

  mergeDefaultDocument (count) {
    const tr = this.tr;
    while (tr.doc.content.childCount > count) {
      const nodesize = tr.doc.content.lastChild ? tr.doc.content.lastChild.nodeSize : 0;
      let depth = 1;
      if (tr.doc.content.lastChild !== tr.doc.content.firstChild) {
        const prePage = tr.doc.content.child(tr.doc.content.childCount - 2);
        const lastPage = tr.doc.content.lastChild;

        if ((lastPage?.firstChild?.type === prePage?.lastChild?.type || lastPage?.firstChild?.type.name.includes('Extend')) && lastPage?.firstChild?.attrs?.extend) {
          depth = 2;
        }
      }
      tr.join(tr.doc.content.size - nodesize, depth);
    }
    this.tr = tr;
  }

  mergeDocument () {
    const tr = this.tr;
    const {selection} = this.state;
    const count = tr.doc.content.findIndex(selection.head).index + 1;
    this.mergeDefaultDocument(count);
  }

  splitPage ({pos, depth = 1, typesAfter, schema}) {
    const tr = this.tr;
    const $pos = tr.doc.resolve(pos);
    let before = Fragment.empty;
    let after = Fragment.empty;

    for (let d = $pos.depth, e = $pos.depth - depth, i = depth - 1; d > e; d--, i--) {
      before = Fragment.from($pos.node(d).copy(before));
      const typeAfter = typesAfter && typesAfter[i];
      const n = $pos.node(d);
      let na = $pos.node(d).copy(after);

      if (schema.nodes[n.type.name + 'Extend']) {
        const attr = Object.assign({}, n.attrs, {id: getId()});
        na = schema.nodes[n.type.name + 'Extend'].createAndFill(attr, after);
      } else {
        if (na && na.attrs.id) {
          let extend = {};
          if (na.attrs.extend === false) {
            extend = {extend: true};
          }
          const attr = Object.assign({}, n.attrs, {id: getId(), ...extend});
          na = schema.nodes[n.type.name].createAndFill(attr, after);
        }
      }
      after = Fragment.from(
        typeAfter
          ? typeAfter.type.create(
            {
              id: getId(),
              pageNumber: na?.attrs.pageNumber + 1,
            },
            after,
          )
          : na,
      );
    }

    tr.step(new ReplaceStep(pos, pos, new Slice(before.append(after), depth, depth)));
    this.tr = tr;
  }

  checkNodeAndFix () {
    let tr = this.tr;
    const {doc} = tr;
    const {schema} = this.state;
    let beforeBlock = null;

    doc.descendants((node, pos, parentNode, i) => {
      if (node.type === schema.nodes.paragraph && node.attrs.extend === true) {
        if (beforeBlock == null) {
          beforeBlock = node;
        } else {
          const mappedPos = tr.mapping.map(pos);
          if (beforeBlock.type !== schema.nodes.paragraph) {
            tr = tr.step(new ReplaceStep(mappedPos - 1, mappedPos + 1, Slice.empty));
          }
          return false;
        }
      }
      if (node.type === schema.nodes.nodeExtend) {
        beforeBlock = null;
        return true;
      }
    });

    this.tr = tr;
    return this.tr;
  }

  getNodeHeight () {
    const doc = this.tr.doc;
    const {bodyOptions} = this.pageState;
    const splitContext = new SplitContext(doc, bodyOptions?.bodyHeight - bodyOptions?.bodyPadding * 2, PARAGRAPH_DEFAULT_HEIGHT);
    const nodesComputed = this.nodesComputed;

    doc.descendants((node, pos, parentNode, i) => {
      if (!splitContext.pageBoundary()) {
        let dom = document.querySelector(`[data-id="${node.attrs.id}"]`)
        if (!dom && node.type.name !== 'page') dom = getAbsentHtml(node);
        return nodesComputed[node.type.name](splitContext, node, pos, parentNode, dom);
      }
      return false;
    });

    return splitContext.pageBoundary() ? splitContext.pageBoundary() : null;
  }
}
