import {Extension} from '@tiptap/core';
import {Page} from './page/Page';
import {PagePlugin} from './page/PagePlugin';
import {KeyMapping} from './KeyMapping';

export const PageExtension = Extension.create({
  name: 'PageExtension',

  addProseMirrorPlugins () {
    return [
      PagePlugin(this.editor, this.options),
    ];
  },

  addExtensions () {
    return [
      Page.configure(this.options),
      KeyMapping,
    ];
  },
});
