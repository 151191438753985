import React, {useState, useCallback, useEffect, useRef} from 'react';
import {useCreateThread} from '../../liveblocks.config';
import {Composer} from '@liveblocks/react-comments';
import {removeCommentHighlight, highlightEvent} from './comment-utils';
import PropTypes from 'prop-types';

const CommentComposer = ({editor, readOnlySave, openComments, containerRef, isDrawerOpen}) => {
  const [position, setPosition] = useState({left: -9999, top: -9999});
  const composer = useRef(null);
  const createThread = useCreateThread();

  const updatePosition = useCallback(() => {
    if (!containerRef.current) return;
    if (editor?.storage.commentHighlight.showComposer) {
      const highlightId = editor.storage.commentHighlight.currentHighlightId;
      const highlightElement = document.querySelector(`[data-highlight-id="${highlightId}"]`);

      if (highlightElement) {
        const containerRect = containerRef.current.getBoundingClientRect();
        const highlightRect = highlightElement.getBoundingClientRect();
        const offsetRight = containerRect.right;

        setPosition({
          left: offsetRight + 10,
          top: highlightRect.bottom - containerRect.top,
        });
      }
    }
  }, [editor, containerRef]);

  useEffect(() => {
    const timeoutId = setTimeout(updatePosition, 300);

    window.addEventListener('resize', updatePosition);
    return () => {
      clearTimeout(timeoutId);
      window.removeEventListener('resize', updatePosition);
    };
  }, [editor, updatePosition, isDrawerOpen]);

  const handleComposerSubmit = useCallback(({body}, event) => {
    event.preventDefault();

    const highlightId = editor?.storage.commentHighlight.currentHighlightId;
    if (!highlightId) {
      return;
    }

    createThread({
      body,
      metadata: {resolved: false, highlightId},
    });

    editor.commands.setCommentHighlight({
      highlightId,
      state: 'complete',
    });

    editor.storage.commentHighlight.currentHighlightId = null;
    editor.storage.commentHighlight.showComposer = false;
    editor.storage.commentHighlight.previousHighlightSelection = null;
    setTimeout(() => {
      openComments();
    }, 50)
    readOnlySave();

    setTimeout(() => {
      highlightEvent(highlightId, true)
    }, 300)
  }, [editor, createThread]);

  // Close composer on outside click within the app
  useEffect(() => {
    if (!composer.current) {
      return;
    }

    const element = composer.current;

    const handleClickOutside = (event) => {
      // Ignore clicks inside the composer
      if (element.contains(event.target)) {
        return;
      }

      // Don't close the composer if there is text in the editor already
      const editorElement = document.querySelector('.lb-composer-editor');
      if (editorElement) {
        const editorText = editorElement.innerText.trim();
        const hasText = editorText.length > 0 && editorText !== 'Write a comment…';

        // If there's no text, close the composer
        if (!hasText) {
          removeCommentHighlight(
            editor,
            editor.storage.commentHighlight.currentHighlightId,
          );

          editor.storage.commentHighlight.currentHighlightId = null;
          editor.storage.commentHighlight.showComposer = false;

          const selection = editor.state.selection;
          const clearedSelection = {
            ...selection,
            anchor: selection.head,
          };
          editor.commands.setTextSelection(clearedSelection);
        }
      }
    };

    // Use `mousedown` to detect clicks early
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [editor, composer]);

  return (
    <Composer
      ref={composer}
      onComposerSubmit={handleComposerSubmit}
      style={{
        position: 'absolute',
        top: `${position.top}px`,
        left: `${position.left}px`,
        right: 0,
        width: isDrawerOpen ? 250 : 300,
        border: '1px solid #DDE0DE',
        borderRadius: '4px',
        boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.15)',
      }}
      onClick={(e) => {
        // Don't send up a click event from emoji popout and close the composer
        e.stopPropagation();
      }}
      autoFocus={true}
    />
  );
};

CommentComposer.propTypes = {
  editor: PropTypes.object.isRequired,
  readOnlySave: PropTypes.func.isRequired,
  containerRef: PropTypes.object.isRequired,
  openComments: PropTypes.func.isRequired,
  isDrawerOpen: PropTypes.bool.isRequired,
}

export default CommentComposer;
