import React, {useState, useEffect} from 'react';
import {connect, useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';

// MUI
import {
  Box,
  Button,
  Card,
  Divider,
  Link as MuiLink,
  Stack,
  Tabs,
  Tab,
  Tooltip,
  Typography,
} from '@mui/material';

// Query
import {useGetAccountingTopicQuery} from '../data/disclosuresApi';

import CommonPageWrapper from './CommonPageWrapper';

// Logos
import AmazonLogo from '../assets/peer-company-logos/amazon.png';
import AppleLogo from '../assets/peer-company-logos/apple.png';
import DropboxLogo from '../assets/peer-company-logos/dropbox.png';
import MicrosoftLogo from '../assets/peer-company-logos/microsoft.png';
import IntuitLogo from '../assets/peer-company-logos/intuit.png';
import SalesforceLogo from '../assets/peer-company-logos/salesforce.png';
import ServiceNowLogo from '../assets/peer-company-logos/servicenow.png';
import CopyIcon from '../assets/copy.svg';
import ExternalLinkIcon from '../assets/external-link.svg';

// Util
import PropTypes from 'prop-types';
import {withRequiredAuthInfo} from '@propelauth/react';
import {APP_HEADER_HEIGHT, MAX_PAGE_CONTENT_WIDTH, BLACK_100} from '../App';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {formatDate} from '../util';
import _ from 'lodash';

const DisclosureHeader = ({apiData}) => {
  const guidanceArray = apiData.fasb_guidance
    .split(/(?=ASC)/)
    .filter(Boolean);

  const linkArray = apiData.fasb_link
    .split(/(?=http)/)
    .filter(Boolean);

  return (
    <Box display={'flex'} justifyContent={'center'} width={'100%'} px={10} sx={{backgroundColor: '#F1F4F2'}}>
      <Box maxWidth={MAX_PAGE_CONTENT_WIDTH} width={'100%'} height={188}>
        <Stack direction="row" alignItems="center" justifyContent='space-between' pt={5.25}>
          <Box maxWidth={500}>
            <Typography variant='h1'>{apiData.accounting_topic}</Typography>
          </Box>

          <Box pr={15} pb={2}>
            <Typography variant='body2' fontWeight='bold'>
              FASB Guidance
            </Typography>

            <Box mt={1}>
              {apiData.fasb_guidance === 'No data'
                ? (
                    'No data'
                  )
                : (
                    guidanceArray.map((guidance, index) => (
                      <span key={index}>
                        <MuiLink color={BLACK_100} href={linkArray[index]} target="_blank">
                          {guidance}
                        </MuiLink>
                        {index < guidanceArray.length - 1 && ', '}
                      </span>
                    ))
                  )
              }
            </Box>
          </Box>
        </Stack>
      </Box>
    </Box>
  );
};

DisclosureHeader.propTypes = {
  apiData: PropTypes.shape({
    accounting_topic: PropTypes.string.isRequired,
    fasb_link: PropTypes.string.isRequired,
    fasb_guidance: PropTypes.string.isRequired,
  }).isRequired,
};

const DisclosureTemplate = ({policy, standalone}) => {
  const cardStyles = {
    border: '1px solid #DDE0DE',
    borderRadius: '8px',
    padding: '32px',
    boxShadow: 'none',
  };

  return (
    <Box>
      <Box display='flex' flexDirection='row' justifyContent='space-between' mb={3}>
        <Typography variant='h3' fontSize={22}>Disclosure template</Typography>
        <Typography variant='body1' fontSize={12} sx={{opacity: 0.6, pt: 1}}>Powered by InScope</Typography>
      </Box>

      <Card sx={cardStyles}>
        <Box>
          <Typography variant='body2' fontWeight='bold' fontStyle='italic'>Policy related disclosure:</Typography>

          <Typography mt={3} variant='body1' color={BLACK_100} fontSize={12} sx={{opacity: 0.8, whiteSpace: 'pre-line'}}>
            {policy ? policy.disclosure_template : 'Not applicable'}
          </Typography>

          {policy &&
            <Box mt={3}>
              <CopyButton textToCopy={policy.disclosure_template} />
            </Box>
          }
        </Box>

        <Box my={3}>
          <Divider />
        </Box>

        <Box mt={3}>
          <Typography variant='body2' fontWeight='bold' fontStyle='italic'>Standalone footnote disclosure:</Typography>
          <Typography mt={3} variant='body1' color={BLACK_100} fontSize={12} sx={{opacity: 0.8, whiteSpace: 'pre-line'}}>
            {standalone ? standalone.disclosure_template : 'Not applicable'}
          </Typography>

          {standalone &&
            <Box mt={3}>
              <CopyButton textToCopy={standalone.disclosure_template} />
            </Box>
          }
        </Box>
      </Card>
    </Box>
  );
};

DisclosureTemplate.propTypes = {
  policy: PropTypes.shape({
    disclosure_template: PropTypes.string,
  }),
  standalone: PropTypes.shape({
    disclosure_template: PropTypes.string,
  }),
};

const CopyButton = ({textToCopy}) => {
  const [copied, setCopied] = useState(false);

  const onCopy = ():void => {
    setCopied(true);
  };

  useEffect(() => {
    const t = copied ? setTimeout(() => setCopied(false), 1000) : null;
    return () => clearTimeout(t);
  }, [copied, setCopied]);

  const copyButtonStyles = {
    borderRadius: '8px',
    backgroundColor: '#fff',
    border: '1px solid #DDE0DE',
    boxShadow: 'none',
    color: BLACK_100,
    height: '32px',
    width: '88px',
  };

  return (
    <CopyToClipboard onCopy={onCopy} text={textToCopy}>
      <Tooltip
        PopperProps={{
          disablePortal: true,
        }}
        open={copied}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        title='Copied!'>
        <Button
          variant='text'
          sx={copyButtonStyles}>
          <img src={CopyIcon} height={12} width={12} />
          <Typography sx={{ml: 1}} variant='body2'>Copy</Typography>
        </Button>
      </Tooltip>
    </CopyToClipboard>
  );
}

CopyButton.propTypes = {
  textToCopy: PropTypes.string.isRequired,
};

const OpenSourceButton = ({url}) => {
  const buttonStyles = {
    borderRadius: '8px',
    backgroundColor: '#fff',
    border: '1px solid #DDE0DE',
    boxShadow: 'none',
    color: BLACK_100,
    height: '32px',
    width: '210px',
  };

  return (
    <Button
      component={MuiLink}
      href={url}
      target='_blank'
      variant='text'
      sx={buttonStyles}>
      <Typography sx={{mr: 1}} variant='body2'>Open source document</Typography>
      <img src={ExternalLinkIcon} height={12} width={12} />
    </Button>
  );
}

OpenSourceButton.propTypes = {
  url: PropTypes.string.isRequired,
};

const TabPanel = (props) => {
  const {children, value, index, ...other} = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{height: '100%', pt: 3}}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
};

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

a11yProps.propTypes = {
  index: PropTypes.number.isRequired,
};

const peers = [
  {
    apiName: 'AMAZON COM INC',
    logo: AmazonLogo,
  },
  {
    apiName: 'APPLE INC.',
    logo: AppleLogo,
  },
  {
    apiName: 'DROPBOX, INC.',
    logo: DropboxLogo,
  },
  {
    apiName: 'MICROSOFT CORP',
    logo: MicrosoftLogo,
  },
  {
    apiName: 'INTUIT INC.',
    logo: IntuitLogo,
  },
  {
    apiName: 'SALESFORCE, INC.',
    logo: SalesforceLogo,
  },
  {
    apiName: 'SERVICENOW, INC.',
    logo: ServiceNowLogo,
  },
];

const PeerDisclosures = ({apiData}) => {
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event, newTabValue) => {
    setTabValue(newTabValue);
  };

  const cardStyles = {
    border: '1px solid #DDE0DE',
    borderRadius: '8px',
    boxShadow: 'none',
  };

  const getDisclosureLink = (disclosure) => {
    if (typeof disclosure.disclosure_link === 'string' &&
        (disclosure.disclosure_link.startsWith('http://') || disclosure.disclosure_link.startsWith('https://'))) {
      return disclosure.disclosure_link;
    } else if (typeof disclosure.peer_disclosure_link === 'string' &&
               (disclosure.peer_disclosure_link.startsWith('http://') || disclosure.peer_disclosure_link.startsWith('https://'))) {
      return disclosure.peer_disclosure_link;
    } else {
      return null;
    }
  };

  return (
    <Box>
      <Box display='flex' flexDirection='row' mb={3}>
        <Typography variant='h3' fontSize={22}>Peer examples</Typography>
      </Box>

      <Card sx={cardStyles}>
        <Tabs
          variant='scrollable'
          scrollButtons={false}
          value={tabValue}
          onChange={handleTabChange}
          aria-label='peer-disclosure-tabs'
          TabIndicatorProps={{children: <span />}}
          sx={{
            borderBottom: '1px solid #D7D8DA',
            backgroundColor: '#F1F4F2',
            '& .MuiTabs-indicator': {
              display: 'flex',
              justifyContent: 'center',
              backgroundColor: 'transparent',
              height: '3px',
              '& > span': {
                width: '100%',
                backgroundColor: BLACK_100,
              },
            },
          }}
          indicatorColor="secondary">
          {peers.map((peer, index) => (
            <Tab
              key={index}
              label={
                <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%'}}>
                  <img src={peer.logo} style={{maxWidth: '100%', maxHeight: '100%', objectFit: 'contain'}} alt={`Logo of ${peer.name}`} />
                </Box>
              }
              {...a11yProps(index)}
              sx={{height: '80px', p: 0, minWidth: 0, flexGrow: 1}}
            />
          ))}
        </Tabs>

        {peers.map((peer, index) => {
          const peerDisclosures = apiData.filter(p => p.company_name === peer.apiName);

          if (peerDisclosures.length === 0) {
            return (
              <TabPanel key={index} value={tabValue} index={index}>
                <Box px={4} pb={4} pt={2}>
                  <Typography variant='h3'>Sry, nothing was found.</Typography>
                </Box>
              </TabPanel>
            );
          }

          const policy = peerDisclosures.find(p => p.footnote_type_policy === 'Policy');
          const standalone = peerDisclosures.find(p => p.footnote_standalone === 'Standalone');

          return (
            <TabPanel key={index} value={tabValue} index={index}>
              <Box px={4} pb={4} pt={2}>
                <Box>
                  <Typography variant='h3'>{(policy || standalone).company_name}</Typography>
                  <Typography variant='body1' mt={1}>10-K ({formatDate((policy || standalone).year_end_date)}) FILED: {formatDate((policy || standalone).filing_date)} AUDITORS: {(policy || standalone).auditors}</Typography>
                </Box>

                <Box mt={3}>
                  <Typography variant='body2' fontWeight='bold' fontStyle='italic'>Policy related disclosure:</Typography>
                  <Typography mt={3} variant='body1' color={BLACK_100} fontSize={12} sx={{opacity: 0.8, whiteSpace: 'pre-line'}}>
                    {policy && policy.peer_disclosure !== 'NOT FOUND' ? policy.peer_disclosure : 'Not found'}
                  </Typography>

                  {policy && policy.peer_disclosure !== 'NOT FOUND' &&
                    <Box mt={3} display='flex' flexDirection='row' alignItems='center'>
                      <CopyButton textToCopy={policy.peer_disclosure} />
                      {getDisclosureLink(policy) &&
                        <Box ml={1}>
                          <OpenSourceButton url={getDisclosureLink(policy)} />
                        </Box>
                      }
                    </Box>
                  }
                </Box>

                <Box my={3}>
                  <Divider />
                </Box>

                <Box mt={3}>
                  <Typography variant='body2' fontWeight='bold' fontStyle='italic'>Standalone footnote disclosure:</Typography>
                  <Typography mt={3} variant='body1' color={BLACK_100} fontSize={12} sx={{opacity: 0.8, whiteSpace: 'pre-line'}}>
                    {standalone && standalone.peer_disclosure !== 'NOT FOUND' ? standalone.peer_disclosure : 'Not found'}
                  </Typography>

                  {standalone && standalone.peer_disclosure !== 'NOT FOUND' &&
                    <Box mt={3} display='flex' flexDirection='row' alignItems='center'>
                      <CopyButton textToCopy={standalone.peer_disclosure} />
                      {getDisclosureLink(standalone) &&
                        <Box ml={1}>
                          <OpenSourceButton url={getDisclosureLink(standalone)} />
                        </Box>
                      }
                    </Box>
                  }
                </Box>
              </Box>
            </TabPanel>
          );
        })}
      </Card>
    </Box>
  );
};

PeerDisclosures.propTypes = {
  apiData: PropTypes.arrayOf(
    PropTypes.shape({
      company_name: PropTypes.string.isRequired,
      footnote_type_policy: PropTypes.string,
      footnote_standalone: PropTypes.string,
      year_end_date: PropTypes.string.isRequired,
      filing_date: PropTypes.string.isRequired,
      auditors: PropTypes.string.isRequired,
      peer_disclosure: PropTypes.string.isRequired,
      disclosure_link: PropTypes.string,
      peer_disclosure_link: PropTypes.string,
    }),
  ).isRequired,
};

const DisclosureDetail = () => {
  const {pathname} = useLocation();
  const {currentOrg, accessToken} = useSelector((state) => state.session);
  const [skip, setSkip] = useState(true);
  const {data: apiData, isLoading} = useGetAccountingTopicQuery(
    {topicSlug: pathname.split('/')[2]},
    {skip},
  );

  useEffect(() => {
    setSkip(_.isNil(currentOrg) || _.isEmpty(currentOrg) || _.isNil(accessToken) || _.isEmpty(accessToken));
  }, [currentOrg, accessToken]);

  if (isLoading || !apiData) {
    return;
  }

  return (
    <Stack mt={APP_HEADER_HEIGHT} direction='column' width='100%' overflow={'scroll'}>
      <DisclosureHeader apiData={apiData[0]} />

      <Box display={'flex'} justifyContent={'center'} height={'100%'} px={10}>
        <Box maxWidth={MAX_PAGE_CONTENT_WIDTH} width={'100%'}>
          <CommonPageWrapper>
            <Stack mt={3} direction={'column'} flex={1} spacing={6} pb={10}>
              <DisclosureTemplate
                policy={apiData.find(p => p.footnote_type_policy === 'Policy')}
                standalone={apiData.find(p => p.footnote_standalone === 'Standalone')}
              />
              <PeerDisclosures apiData={apiData} />

              <Box px={2} pt={4}>
                <Typography variant='body2' fontWeight='bold' fontSize={'12px'}>**Disclosure library usage disclaimer**</Typography>
                <Typography mt={1} variant='body1' fontSize={'12px'} sx={{opacity: 0.8}}>
                  The template disclosures are generic and compiled by synthesizing public company disclosures. The goal is to provide a starting point but requires to be modified to fit individual company facts and circumstances. The template may not include all of the required disclosures for a company’s specific facts, newly released guidance or auditor firm interpretations of that guidance. The majority of disclosures templates are drafted for private companies and show 2 year comparative.
                </Typography>
              </Box>
            </Stack>
          </CommonPageWrapper>
        </Box>
      </Box>
    </Stack>
  );
}

const mapStateToProps = (state) => {
  return {
    session: state.session,
  }
}

export default connect(mapStateToProps)(withRequiredAuthInfo(DisclosureDetail));
