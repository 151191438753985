import React, {useState} from 'react';
import {Link, useNavigate, useLocation, useParams} from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';

// MUI
import {
  AppBar,
  Avatar,
  Box,
  Breadcrumbs,
  Button,
  Divider,
  Link as MuiLink,
  ListItemIcon,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
  IconButton,
} from '@mui/material';

// Icons
import Logo from '../assets/logo2.svg';
import HelpIcon from '../assets/help-circle.svg';
import ShieldIcon from '../assets/shield.svg';
import BookOpenIcon from '../assets/book-open.svg';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckIcon from '@mui/icons-material/Check';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import {
  useHostedPageUrls,
  useLogoutFunction,
  useRedirectFunctions,
  withRequiredAuthInfo,
} from '@propelauth/react';
import {useFlags, withLDConsumer} from 'launchdarkly-react-client-sdk';
import {setOrg} from '../data/session';
import {connect, useDispatch, useSelector} from 'react-redux';
import {APP_HEADER_HEIGHT, GREEN_NAV} from '../App';
import {useGetDocumentQuery} from '../data/documentWorkspaceApi';

const OrgSwitcher = ({currentOrg, orgs, handleSetOrg}) => {
  return (
    <Box>
      <MenuItem
        disableRipple
        onClick={(e) => e.preventDefault()}
        sx={{
          '&:hover': {
            backgroundColor: 'transparent',
            cursor: 'default',
          },
          pointerEvents: 'none',
        }}
      >
        <Typography color='#5F8882' fontWeight={600} fontSize={10}>USE DASHBOARD AS...</Typography>
      </MenuItem>

      {orgs.map((org, index) => (
        <MenuItem
          key={index}
          onClick={() => handleSetOrg(org)}
          sx={{height: 32, display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}
        >
          <Typography color='#052E28' fontWeight={400}>{org.orgName}</Typography>

          {currentOrg.orgId === org.orgId &&
            <ListItemIcon sx={{minWidth: 'auto', marginLeft: 'auto'}}>
              <CheckIcon sx={{color: '#0F1E24', width: 16, height: 16}} />
            </ListItemIcon>
          }
        </MenuItem>
      ))}
    </Box>
  )
};

OrgSwitcher.propTypes = {
  currentOrg: PropTypes.shape({
    orgId: PropTypes.string,
    orgName: PropTypes.string,
  }),
  orgs: PropTypes.arrayOf(
    PropTypes.shape({
      orgId: PropTypes.string.isRequired,
      orgName: PropTypes.string.isRequired,
    }),
  ).isRequired,
  handleSetOrg: PropTypes.func.isRequired,
};

const UserAvatar = ({user, bgColor, textColor, size}) => {
  return (
    <Avatar
      alt={'Jared'}
      sx={{
        fontSize: '14px',
        fontWeight: 'bold',
        width: size,
        height: size,
        backgroundColor: bgColor,
        color: textColor,
      }}>
      {user.firstName[0]}
    </Avatar>
  )
};

UserAvatar.propTypes = {
  user: PropTypes.shape({
    pictureUrl: PropTypes.string,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string,
  }).isRequired,
  bgColor: PropTypes.string,
  textColor: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

const UserMenu = ({
  user,
  currentOrg,
  orgs,
  handleSetOrg,
  anchorElUser,
  handleCloseUserMenu,
  handleMenuClick,
  getAccountPageUrl,
}) => {
  return (
    <Menu
      sx={{mt: '40px'}}
      disableAutoFocusItem={true}
      PaperProps={{
        style: {
          width: '232px',
          borderRadius: '4px',
          boxShadow: '0px 2px 12px 2px rgba(15, 30, 36, 0.12)',
        },
      }}
      id="menu-appbar"
      anchorEl={anchorElUser}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={Boolean(anchorElUser)}
      onClose={handleCloseUserMenu}
    >
      <MenuItem component={Link} to={getAccountPageUrl()} sx={{height: 50}} key="account" onClick={() => handleMenuClick('account')}>
        <Box display='flex' flexDirection='row' alignItems='center'>
          <UserAvatar bgColor={GREEN_NAV} textColor='#fff' size={28} user={user} />
          <Box ml={1}>
            <Typography color='#052E28' fontWeight={700}>{user?.firstName} {user?.lastName}</Typography>
            <Typography color='#686A6A' fontSize='11px' fontWeight={400}>{user?.email}</Typography>
          </Box>
        </Box>
      </MenuItem>

      <MenuItem component={Link} to={getAccountPageUrl()} sx={{height: 40}} key="account-settings" onClick={() => handleMenuClick('account')}>
        <Typography color='#052E28' fontWeight={400}>Account settings</Typography>
      </MenuItem>

      <Divider sx={{my: 2, backgroundColor: '#DADFDB'}} />

      <OrgSwitcher currentOrg={currentOrg} orgs={orgs} handleSetOrg={handleSetOrg} />

      <Divider sx={{mt: 2, mb: 1, backgroundColor: '#DADFDB'}} />

      <MenuItem sx={{height: 40}} key="logout" onClick={() => handleMenuClick('logout')}>
        <Typography color='#052E28' fontWeight={400}>Log out</Typography>
      </MenuItem>
    </Menu>
  );
};

UserMenu.propTypes = {
  user: PropTypes.shape({
    email: PropTypes.string.isRequired,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    pictureUrl: PropTypes.string,
  }).isRequired,
  currentOrg: PropTypes.shape({
    orgId: PropTypes.string,
    orgName: PropTypes.string,
  }),
  orgs: PropTypes.arrayOf(
    PropTypes.shape({
      orgId: PropTypes.string.isRequired,
      orgName: PropTypes.string.isRequired,
    }),
  ).isRequired,
  handleSetOrg: PropTypes.func.isRequired,
  anchorElUser: PropTypes.object,
  handleCloseUserMenu: PropTypes.func.isRequired,
  handleMenuClick: PropTypes.func.isRequired,
  getAccountPageUrl: PropTypes.func.isRequired,
};

const ResourcesMenu = ({flags, anchorElResources, handleCloseResourcesMenu, handleMenuClick}) => {
  return (
    <Menu
      sx={{mt: '40px'}}
      disableAutoFocusItem={true}
      PaperProps={{
        style: {
          width: '204px',
          borderRadius: '4px',
          boxShadow: '0px 2px 12px 2px rgba(15, 30, 36, 0.12)',
        },
      }}
      id="menu-appbar"
      anchorEl={anchorElResources}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={Boolean(anchorElResources)}
      onClose={handleCloseResourcesMenu}
    >
      {flags.disclosureGuidance &&
        <MenuItem component={Link} to={'/disclosure-library'} target="_blank" sx={{height: 40}} key="disclosure-library" onClick={() => handleMenuClick('disclosure-library')}>
          <ListItemIcon>
            <img
              src={BookOpenIcon}
              width={16}
              height={16}
            />
          </ListItemIcon>
          <Typography color='#052E28'>Disclosure library</Typography>
        </MenuItem>
      }

      {flags.disclosureGuidance && <Divider sx={{my: 2, backgroundColor: '#DADFDB'}} />}

      <MenuItem component={Link} to={'/support'} sx={{height: 40}} key="help" onClick={() => handleMenuClick('help')}>
        <ListItemIcon>
          <img
            src={HelpIcon}
            width={16}
            height={16}
          />
        </ListItemIcon>
        <Typography color='#052E28'>Help</Typography>
      </MenuItem>

      <Divider sx={{my: 2, backgroundColor: '#DADFDB'}} />

      <MenuItem component={MuiLink} href="https://www.inscopehq.com/policies-and-terms/terms-of-service" target="_blank" sx={{height: 40}} key="legal" onClick={() => handleMenuClick('legal')}>
        <ListItemIcon>
          <img
            src={ShieldIcon}
            width={16}
            height={16}
          />
        </ListItemIcon>
        <Typography color='#052E28'>Legal</Typography>
      </MenuItem>
    </Menu>
  );
};

ResourcesMenu.propTypes = {
  anchorElResources: PropTypes.object,
  handleCloseResourcesMenu: PropTypes.func.isRequired,
  handleMenuClick: PropTypes.func.isRequired,
  flags: PropTypes.shape({
    disclosureGuidance: PropTypes.bool.isRequired,
  }).isRequired,
};

// TODO make more dynamic and reusable
const NavBreadcrumbs = ({pathname}) => {
  const {multipleDocuments} = useFlags();
  const {documentId} = useParams();
  const {currentOrg} = useSelector((state) => state.session);
  const {data, isFetching, isSuccess} = useGetDocumentQuery({documentId}, {skip: !multipleDocuments || _.isNil(documentId) || _.isNil(currentOrg)});
  const deslugify = (slug) => {
    return slug
      .split('-')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  const backPath = pathname.startsWith('/disclosure-library/') ? '/disclosure-library' : '/documents';

  const _getFilename = () => {
    if (!multipleDocuments) {
      return 'Annual Report';
    } else if (!isFetching && isSuccess) {
      return data.record.name
    }
    return '';
  }

  return (
    <Breadcrumbs
      separator={<ChevronRightIcon sx={{color: '#fff', width: 12, height: 12}} />}
      aria-label='breadcrumb'
    >
      <MuiLink component={Link} to={backPath} color='rgba(255, 255, 255, 0.80)' sx={{textDecoration: 'none'}}>
        {pathname.startsWith('/disclosure-library/') ? 'Disclosure library' : 'Documents'}
      </MuiLink>
      <Typography color='#fff'>
        {pathname.startsWith('/disclosure-library/') ? deslugify(pathname.split('/').pop()) : _getFilename()}
      </Typography>
    </Breadcrumbs>
  );
};

NavBreadcrumbs.propTypes = {
  pathname: PropTypes.string,
};

const AppBarV2 = ({user, session, flags}) => {
  const {documentId} = useParams();
  const {currentOrg, orgs} = session;
  const dispatch = useDispatch();
  const {getAccountPageUrl} = useHostedPageUrls();
  const logoutFn = useLogoutFunction();
  const navigate = useNavigate();
  const location = useLocation();
  const {redirectToLoginPage} = useRedirectFunctions();
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [anchorElResources, setAnchorElResources] = useState(null);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleOpenResourcesMenu = (event) => {
    setAnchorElResources(event.currentTarget);
  };

  const handleCloseResourcesMenu = () => {
    setAnchorElResources(null);
  };

  const handleMenuClick = async (item) => {
    handleCloseUserMenu();
    handleCloseResourcesMenu();

    if (item === 'logout') {
      await logoutFn(false);
      redirectToLoginPage({postLoginRedirectUrl: window.location.href});
    }
  };

  const handleSetOrg = (org) => {
    handleCloseUserMenu();
    dispatch(setOrg({org}));
    navigate('/');
  }

  return (
    <AppBar position="fixed" sx={{height: APP_HEADER_HEIGHT, zIndex: (theme) => theme.zIndex.drawer, boxShadow: 0}}>
      <Toolbar disableGutters sx={{height: '100%', minHeight: APP_HEADER_HEIGHT + '!important', backgroundColor: GREEN_NAV}}>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
          component={Link}
          to="/"
          sx={{width: '100px', height: '100%', pl: 1}}
        >
          <img src={Logo} alt="Logo" width={78} height={24} />
        </Box>

        <Box pl={2}>
          {(location.pathname === '/document' || (_.startsWith(location.pathname, '/documents/') && !_.isNil(documentId)) || location.pathname.startsWith('/disclosure-library/')) && <NavBreadcrumbs pathname={location.pathname} />}
        </Box>

        <Box flexGrow={1} />

        <Box sx={{flexGrow: 0, mr: 2}}>
          <Box display="flex" flexDirection="row" alignItems="center">
            <Button
              endIcon={<ExpandMoreIcon sx={{color: 'rgba(255, 255, 255, 0.80)', width: 12, height: 12}} />}
              onClick={handleOpenResourcesMenu}
              variant="text"
              sx={{color: '#fff', mr: 2, pb: 1}}
            >
              Resources
            </Button>

            <Typography color='rgba(255, 255, 255, 0.80)' sx={{pr: 2, fontWeight: 600, fontSize: 12}}>{currentOrg?.orgName?.toUpperCase()}</Typography>
            <IconButton onClick={handleOpenUserMenu} sx={{p: 0}}>
              <UserAvatar user={user} bgColor='#fff' textColor={GREEN_NAV} size={24} />
              <ExpandMoreIcon sx={{color: 'rgba(255, 255, 255, 0.80)', width: 12, height: 12, ml: 0.5}} />
            </IconButton>
          </Box>

          <UserMenu
            anchorElUser={anchorElUser}
            handleCloseUserMenu={handleCloseUserMenu}
            handleMenuClick={handleMenuClick}
            getAccountPageUrl={getAccountPageUrl}
            user={user}
            currentOrg={currentOrg}
            orgs={orgs}
            handleSetOrg={handleSetOrg}
          />

          <ResourcesMenu
            anchorElResources={anchorElResources}
            handleCloseResourcesMenu={handleCloseResourcesMenu}
            handleMenuClick={handleMenuClick}
            flags={flags}
          />
        </Box>
      </Toolbar>
    </AppBar>
  );
}

AppBarV2.propTypes = {
  user: PropTypes.shape({
    pictureUrl: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
  }).isRequired,
  session: PropTypes.shape({
    currentOrg: PropTypes.shape({
      orgId: PropTypes.string,
      orgName: PropTypes.string,
    }),
    orgs: PropTypes.arrayOf(
      PropTypes.shape({
        orgId: PropTypes.string.isRequired,
        orgName: PropTypes.string.isRequired,
      }),
    ).isRequired,
  }).isRequired,
  flags: PropTypes.shape({
    disclosureGuidance: PropTypes.bool.isRequired,
  }).isRequired,
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
  }
}

export default connect(mapStateToProps)(withLDConsumer()(withRequiredAuthInfo(AppBarV2)));
