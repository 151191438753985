import {Node, ReactNodeViewRenderer} from '@tiptap/react';
import DocumentTable from './DocumentTable';

const DocumentTableNodeView = Node.create({
  name: 'table',
  group: 'block',
  atom: true,
  draggable: true,

  addOptions () {
    return {
      isPrinting: false,
    };
  },

  addAttributes () {
    return {
      'table-data': '[]',
      'table-meta': '{}',
      'table-merged-cells': '[]',
      'table-size-multiplier': 1,
      'table-height': {
        default: 122,
      },
      'is-linked': {
        default: false,
      },
      'linked-item-id': {
        default: null,
      },
    };
  },

  parseHTML () {
    return [
      {
        tag: 'document-table',
      },
    ];
  },

  renderHTML ({HTMLAttributes}) {
    return ['document-table', HTMLAttributes];
  },

  addNodeView () {
    return ReactNodeViewRenderer(DocumentTable);
  },
});

export default DocumentTableNodeView;
