import React, {useState, useEffect, useCallback} from 'react'
import PropTypes from 'prop-types';

// Custom components
import CommonPageWrapper from '../../components/CommonPageWrapper';
import CommentComposer from '../../components/report-workspace/CommentComposer';
import CommentDrawer from '../../components/report-workspace/CommentDrawer';
import CommentSelectionMenu from '../../components/report-workspace/CommentSelectionMenu';
import DocumentToolbar from '../../components/report-workspace/DocumentToolbar';

// Tiptap
import {mergeAttributes} from '@tiptap/core'
import StarterKit from '@tiptap/starter-kit'
import Heading from '@tiptap/extension-heading';
import Underline from '@tiptap/extension-underline'
import TextAlign from '@tiptap/extension-text-align'
import UniqueID from '@tiptap-pro/extension-unique-id';
import {EditorContent, PureEditorContent, useEditor} from '@tiptap/react';
import {Indent} from '../../components/report-workspace/Indent';
import {PageExtension} from '../../components/report-workspace/PageExtension';
import {PagedDocument} from '../../components/report-workspace/PagedDocument';
import {DocumentPasteHandler} from '../../components/report-workspace/DocumentPasteHandler';
import DocumentTableNodeView from '../../components/report-workspace/DocumentTable/DocumentTableNodeView';

// Collaboration
import {LiveblocksCommentsHighlight} from '../../components/report-workspace/CommentHighlight';

// MUI
import {
  Box,
  Stack,
  useTheme,
} from '@mui/material';

// Utils
import {COPILOT_DRAWER_WIDTH} from '../../App';
import {debounce} from 'lodash';

// https://github.com/ueberdosis/tiptap/issues/4492
PureEditorContent.prototype.maybeFlushSync = (fn: () => void) => {
  fn();
};

const DocumentEditor = React.memo(({
  canEdit,
  content,
  contentToPrintRef,
  commentsOpen,
  closeComments,
  copilotOpen,
  editorRef,
  enterPrintMode,
  isPrinting,
  isSaving,
  onPrintContentReady,
  openCopilot,
  openComments,
  toggleComments,
  saveDocument,
}) => {
  const [isTableActive, setIsTableActive] = useState(false);

  const theme = useTheme();

  const debouncedSaveDocument = useCallback(
    debounce(() => {
      if (canEdit && editorRef.current) {
        saveDocument(editorRef.current);
      }
    }, 1000),
    [canEdit],
  );

  const triggerSaveForReadOnly = useCallback(() => {
    if (!canEdit && editorRef.current) {
      saveDocument(editorRef.current);
    }
  }, [canEdit]);

  const editorExtensions = [
    PagedDocument,
    PageExtension.configure({
      bodyPadding: 46,
      bodyWidth: 816,
      headerHeight: 0,
      footerHeight: 0,
      bodyHeight: 1056,
      isPaging: true,
    }),
    DocumentTableNodeView.configure({
      isPrinting,
    }),
    DocumentPasteHandler,
    StarterKit.configure({
      document: false,
      heading: false,
    }),
    Heading.extend({
      levels: [1, 2, 3],
      renderHTML ({node, HTMLAttributes}) {
        const level = this.options.levels.includes(node.attrs.level)
          ? node.attrs.level
          : this.options.levels[0];

        const classes = {
          1: 'text-huge',
          2: 'text-large',
          3: 'text-small',
        };

        return [
          `h${level}`,
          mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, {
            class: `${classes[level]}`,
          }),
          0,
        ];
      },
    }).configure({levels: [1, 2, 3]}),
    Underline,
    Indent,
    TextAlign.configure({
      types: ['heading', 'paragraph'],
      alignments: ['left', 'right', 'center'],
    }),
    LiveblocksCommentsHighlight.configure({
      HTMLAttributes: {
        class: 'comment-highlight',
      },
    }),
    UniqueID.configure({
      types: ['heading', 'paragraph', 'orderedList', 'bulletList', 'listItem', 'table', 'horizontalRule'],
    }),
  ];

  const editor = useEditor({
    extensions: editorExtensions,
    content,
    onUpdate: debouncedSaveDocument,
    editable: canEdit,
    // Uncomment to disable spellcheck
    // editorProps: {
    //   attributes: {
    //     spellcheck: false,
    //   },
    // },
    onCreate: ({editor}) => {
      editorRef.current = editor;
    },
  });

  const addTableToPage = useCallback(() => {
    const defaultTableData = [
      ['', '', '', ''], // default merged header cell
      ['', '2024', '', '2023'], // default header cell
      ['', '', '', ''],
      ['', '', '', ''],
      ['', '', '', ''],
      ['', '', '', ''],
    ];

    const defaultTableDataJSON = JSON.stringify(defaultTableData);
    const defaultMergedCells = [{row: 0, col: 1, rowspan: 1, colspan: 3}];
    const defaultMergedCellsJSON = JSON.stringify(defaultMergedCells);

    const tableHTML = `<document-table table-data='${defaultTableDataJSON}' table-meta='{}' table-merged-cells='${defaultMergedCellsJSON}' table-size-multiplier='1'></document-table><p></p>`;

    editor
      .chain()
      .focus()
      .insertContent(tableHTML)
      .run();
  }, [editor]);

  useEffect(() => {
    setTimeout(() => {
      editor?.value?.view.dispatch(editor.value?.state.tr.setMeta('splitPage', true));
    }, 1000);
  }, []);

  useEffect(() => {
    editor?.commands.focus('start');
  }, [editor]);

  useEffect(() => {
    if (isPrinting && editor && onPrintContentReady) {
      onPrintContentReady();
    }
  }, [isPrinting, editor, onPrintContentReady]);

  useEffect(() => {
    if (!editor) return;

    const handlePointerDown = (event) => {
      const tableElement = event.target.closest('.handsontable');

      if (tableElement) {
        setIsTableActive(true);
      } else {
        setIsTableActive(false);
      }
    };

    document.addEventListener('mousedown', handlePointerDown);

    return () => {
      document.removeEventListener('mousedown', handlePointerDown);
    };
  }, [editor]);

  const contentWrapperStyle = {
    flexGrow: 1,
    display: 'flex',
    height: '100%',
    px: '1.8675rem',
    overflow: 'scroll',
    scrollPaddingBlockStart: '20%',
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: 0,
    ...((copilotOpen || commentsOpen) && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: COPILOT_DRAWER_WIDTH,
    }),
    /**
     * This is necessary to enable the selection of content. In the DOM, the stacking order is determined
     * by the order of appearance. Following this rule, elements appearing later in the markup will overlay
     * those that appear earlier. Since the Drawer comes after the Main content, this adjustment ensures
     * proper interaction with the underlying content.
     */
    position: 'relative',
  }

  return (
    <>
      <DocumentToolbar
        editor={editor}
        readOnly={!canEdit}
        addTable={addTableToPage}
        openCopilot={openCopilot}
        copilotOpen={copilotOpen}
        openComments={toggleComments}
        commentsOpen={commentsOpen}
        enterPrintMode={enterPrintMode}
        isSaving={isSaving}
        isTableActive={isTableActive}
      />

      <Box mt={'8rem'} sx={contentWrapperStyle}>
        <CommonPageWrapper>
          <Stack direction='column' alignItems='center' flex={1}>
            <Box sx={{display: 'grid', flexGrow: 1, borderRadius: '8px', placeItems: 'center'}}>
              <EditorContent ref={contentToPrintRef} editor={editor} />
            </Box>
          </Stack>

          {editor && <CommentSelectionMenu editor={editor} />}
        </CommonPageWrapper>

        {editor && editor.storage.commentHighlight.showComposer && (
          <CommentComposer
            editor={editor}
            readOnlySave={triggerSaveForReadOnly}
            containerRef={contentToPrintRef}
            isDrawerOpen={commentsOpen || copilotOpen}
            openComments={openComments}
          />
        )}
      </Box>

      {editor &&
        <CommentDrawer
          editor={editor}
          readOnlySave={triggerSaveForReadOnly}
          open={commentsOpen}
          onClose={closeComments}
          readOnly={!canEdit}
        />
      }
    </>
  );
});

DocumentEditor.displayName = 'DocumentEditor';

DocumentEditor.propTypes = {
  canEdit: PropTypes.bool.isRequired,
  content: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]).isRequired,
  contentToPrintRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({current: PropTypes.instanceOf(Element)}),
  ]),
  commentsOpen: PropTypes.bool.isRequired,
  closeComments: PropTypes.func.isRequired,
  copilotOpen: PropTypes.bool.isRequired,
  editorRef: PropTypes.shape({
    current: PropTypes.any,
  }),
  enterPrintMode: PropTypes.func.isRequired,
  isPrinting: PropTypes.bool.isRequired,
  isSaving: PropTypes.bool.isRequired,
  onPrintContentReady: PropTypes.func.isRequired,
  openCopilot: PropTypes.func.isRequired,
  openComments: PropTypes.func.isRequired,
  saveDocument: PropTypes.func.isRequired,
  toggleComments: PropTypes.func.isRequired,
};

export default DocumentEditor;
