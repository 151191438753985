import React, {useCallback, useRef} from 'react';
import {v4 as uuidv4} from 'uuid';
import {Box, Button, Typography} from '@mui/material';
import CommentIcon from '../../assets/comment.svg';
import PropTypes from 'prop-types';

const CommentToolbar = ({editor}) => {
  const wrapper = useRef(null);

  const handleClick = useCallback(async () => {
    // Hack to ensure editor is editable for read-only cases
    const isEditable = editor.isEditable;
    editor.setEditable(true);

    editor
      .chain()
      .focus()
      .setCommentHighlight({
        highlightId: uuidv4(),
        state: 'composing',
      })
      .run();

    setTimeout(() => {
      editor.setEditable(isEditable);
    }, 100);
  }, [editor]);

  return (
    <Box ref={wrapper} sx={{width: '100%'}}>
      <Button
        sx={{width: '100%', height: '100%'}}
        onClick={handleClick}
        disabled={editor.isActive('commentHighlight')}
        data-active={
          editor.isActive('commentHighlight') ? 'is-active' : undefined
        }
        aria-label='Add comment'
      >
        <img width={16} height={16} src={CommentIcon} alt='comment' />
        <Typography sx={{ml: 1, color: '#0F1E24', fontSize: '12px', fontWeight: 500}}>Comment</Typography>
      </Button>
    </Box>
  );
}

CommentToolbar.propTypes = {
  editor: PropTypes.object,
}

export default CommentToolbar;
