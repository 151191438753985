import {Plugin, PluginKey} from '@tiptap/pm/state';
import {findParentDomRefOfType} from './page-utils';
import {defaultNodesComputed, PageComputedContext} from './Computed';
import {findParentNode} from '@tiptap/core';
import {PageState} from './PageState';

class PageDetector {
  constructor (editor, bodyOption, pageClass = '.PageContent') {
    this._bodyOption = bodyOption;
    this._editor = editor;
    this._pageClass = pageClass;
  }

  isOverflown (pageBody) {
    return pageBody.scrollHeight > this._bodyOption.bodyHeight;
  }

  update (view, prevState) {
    const {selection, schema, tr} = view.state;
    if (view.state.doc.eq(prevState.doc)) return;

    const domAtPos = view.domAtPos.bind(view);
    const deleting = window.stepStatus ? window.stepStatus : false;
    const pageDOM = findParentDomRefOfType(schema.nodes.page, domAtPos)(selection);
    if (!pageDOM) return;

    const pageBody = pageDOM.querySelector(this._pageClass);
    if (pageBody) {
      const inserting = this.isOverflown(pageBody);
      if (inserting) {
        const currPage = findParentNode((n) => n.type.name === 'page')(selection);
        if (currPage) {
          const {childCount, firstChild} = currPage.node;
          if (childCount === 1 && firstChild?.type.name === 'table' && firstChild.childCount === 1) {
            return;
          }
        }
      }

      if (inserting || deleting) {
        if (inserting) tr.setMeta('inserting', inserting);
        if (deleting) {
          tr.setMeta('deleting', true);
          window.stepStatus = false;
        }
        const state = view.state.apply(tr);
        view.updateState(state);
      } else {
        if (window.checkNode) {
          window.checkNode = false;
          tr.setMeta('checkNode', true);
          const state = view.state.apply(tr);
          view.updateState(state);
        }
      }
    }
  }
}

export const paginationPluginKey = new PluginKey('pagination');

export const PagePlugin = (editor, bodyOption) => {
  const plugin = new Plugin({
    key: paginationPluginKey,
    view: () => {
      return new PageDetector(editor, bodyOption);
    },
    state: {
      init: () => {
        return new PageState(bodyOption, false, false, false, false);
      },
      apply: (tr, prevState) => {
        return prevState.transform(tr);
      },
    },
    appendTransaction ([newTr], _prevState, state) {
      const page = new PageComputedContext(editor, defaultNodesComputed, this.getState(state), state);
      return page.run();
    },
    props: {
      handleTextInput (view, form, to, text) {
        return false;
      },
      handleKeyDown (view, event) {
        if (event.code === 'Backspace') {
          window.stepStatus = true;
        } else {
          window.stepStatus = false;
        }
        return false;
      },
    },
  });

  return plugin;
};
