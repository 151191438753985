export const menuItemDisplay = (name, icon) => {
  const iconElement = document.createElement('img');
  iconElement.src = icon;
  iconElement.style.height = '16px';
  iconElement.style.width = '16px';
  iconElement.style.marginRight = '10px';
  iconElement.style.marginBottom = '1px';

  const text = document.createTextNode(name);

  const container = document.createElement('div');
  container.appendChild(iconElement);
  container.appendChild(text);

  return container.outerHTML;
};
