import {useCallback, useEffect} from 'react';

// Remove a comment highlight from `data-highlight-id`
export function removeCommentHighlight (editor, highlightId) {
  let result = false;

  // Iterate over each mark in the text node and find a match
  editor.state.doc.descendants((node, pos) => {
    if (node.isText) {
      node.marks.forEach((mark) => {
        const dataId = mark.attrs.highlightId;

        if (dataId === highlightId && node.text) {
          const from = pos;
          const to = pos + node.text.length;

          // Create and dispatch a transaction to remove the mark
          const transaction = editor.state.tr.removeMark(from, to, mark.type);
          editor.view.dispatch(transaction);
          result = true;
        }
      });
    }
  });

  return result;
}

// Get the mark and node of a comment highlight from `data-highlight-id`
export function getCommentHighlight (editor, highlightId) {
  let result = null;

  // Iterate over each mark in the text node and find a matching mark
  editor.state.doc.descendants((node, pos) => {
    if (node.isText) {
      node.marks.forEach((mark) => {
        const dataId = mark.attrs.highlightId;

        if (dataId === highlightId) {
          result = {mark, node};
        }
      });
    }
  });

  return result;
}

export function getCommentHighlightContent (highlightId) {
  const elem = document.querySelector(
    `mark[data-highlight-id="${highlightId}"]`,
  );

  if (!elem) {
    return null;
  }

  return elem.innerHTML;
}

export const HIGHLIGHT_EVENT_NAME = 'commentHighlight';

// Trigger highlight active
export function highlightEvent (highlightId, isClick = false) {
  const event = new CustomEvent(HIGHLIGHT_EVENT_NAME, {
    detail: {highlightId, isClick},
  });

  document.documentElement.dispatchEvent(event);
}

export function useHighlightEvent () {
  return useCallback((highlightId) => {
    const event = new CustomEvent(HIGHLIGHT_EVENT_NAME, {
      detail: {highlightId},
    });

    document.documentElement.dispatchEvent(event);
  }, []);
}

export function useHighlightEventListener (callback) {
  useEffect(() => {
    function handler (event) {
      callback(event.detail.highlightId, event.detail.isClick);
    }

    document.documentElement.addEventListener(
      HIGHLIGHT_EVENT_NAME,
      handler,
    );

    return () => {
      document.documentElement.removeEventListener(
        HIGHLIGHT_EVENT_NAME,
        handler,
      );
    };
  }, [callback]);
}
