import React, {memo, useEffect, useRef, useState} from 'react'
import {connect, useSelector} from 'react-redux';
import {withRequiredAuthInfo} from '@propelauth/react';
import {Box, Button, Stack, Card, styled, Typography} from '@mui/material';
import AppPage from './AppPage';
import CommonPageWrapper from '../components/CommonPageWrapper';
import DocumentIcon from '../assets/file-text.svg';
import FileIcon from '../assets/file.svg';
import {
  useCreateDocumentMutation,
  useDeleteDocumentMutation,
  useGetDocumentsQuery,
  useUpdateDocumentMutation,
} from '../data/documentWorkspaceApi';
import TableContainer from '@mui/material/TableContainer';
import {APP_HEADER_HEIGHT, FOOTER_HEIGHT} from '../App';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import TableCell from '@mui/material/TableCell';
import {sortRecords} from '../util';
import {visuallyHidden} from '@mui/utils';
import _ from 'lodash';
import TableBody from '@mui/material/TableBody';
import Chip from '@mui/material/Chip';
import TimeAgo from '../components/TimeAgo';
import IconButton from '@mui/material/IconButton';
import MoreHoriz from '@mui/icons-material/MoreHoriz';
import {dotPulse} from 'ldrs';
import {BLACK_100, WHITE_100} from '../theme';
import AddIcon from '@mui/icons-material/Add';
import Error from '../components/Error';
import {withLDConsumer} from 'launchdarkly-react-client-sdk';
import {Link, useNavigate} from 'react-router-dom';
import PropTypes from 'prop-types';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Modal from '@mui/material/Modal';
import FormControl from '@mui/material/FormControl';
import Divider from '@mui/material/Divider';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InputAdornment from '@mui/material/InputAdornment';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Switch from '@mui/material/Switch';
import {bindTrigger, bindMenu} from 'material-ui-popup-state';
import {usePopupState} from 'material-ui-popup-state/hooks';
import CloseIcon from '@mui/icons-material/Close';
import spacetime from 'spacetime';
import LoadingButton from '@mui/lab/LoadingButton';
import CheckIcon from '@mui/icons-material/Check';
import Loading from '../components/Loading';

dotPulse.register();

const QUARTERS = [
  'Q1',
  'Q2',
  'Q3',
  'Q4',
];

const YEARS = [
  spacetime.now().subtract(1, 'year').format('year'),
  spacetime.now().format('year'),
  spacetime.now().add(1, 'year').format('year'),
]

const TABLE_HEADERS = [
  {
    label: 'Name',
    id: 'name',
    width: 'auto',
    maxWidth: '30.25rem',
  },
  {
    label: 'File type',
    id: 'type',
    width: '12.5rem',
  },
  {
    label: 'Status',
    id: 'status',
    width: '12.5rem',
  },
  {
    label: 'Last modified',
    id: 'updatedAt',
    width: '16.75rem',
  },
];

const styles = {
  createButton: {
    background: '#38CF7A',
    borderRadius: '0.5rem',
    paddingLeft: '0.75rem',
    paddingRight: '1rem',
    paddingVertical: '0.625rem',
    height: '2.5rem',
    '&:hover': {
      backgroundColor: '#38CF7A',
    },
  },
  createButtonLabel: {
    fontSize: '0.875rem',
    lineHeight: '140%',
    fontWeight: '500',
  },
  cancelButton: {
    borderRadius: '0.5rem',
    paddingVertical: '0.6825rem',
    paddingHorizontal: '1rem',
    height: '2.5rem',
    '&:hover': {
      backgroundColor: WHITE_100,
    },
  },
  cancelButtonLabel: {
    color: '#043B34',
    fontSize: '0.875rem',
    fontWeight: '500',
    lineHeight: '130%',
  },
  recentDocuments: {
    label: {
      color: '#043B34',
      fontSize: '1rem',
      fontWeight: '600',
      lineHeight: '120%',
    },
  },
  table: {
    container: {
      borderRadius: '0.25rem',
      border: '1px #DDE0DE',
      background: '#F3F4F4',
    },
    header: {
      cell: {
        height: '2.0625rem',
        backgroundColor: '#F3F4F4',
        padding: '0.5rem 1rem',
        alignItems: 'center',
        marginBottom: '0.75rem',
      },
      label: {
        color: 'rgba(4, 59, 52, 0.70)',
        fontSize: '0.75rem',
        fontWeight: '600',
        lineHeight: '140%',
      },
    },
    row: {
      label: {
        fontSize: '0.875rem',
        fontWeight: '400',
        lineHeight: '140%',
        color: '#043B34',
      },
      secondaryLabel: {
        fontSize: '0.75rem',
        fontWeight: '400',
        lineHeight: '140%',
        color: '#043B34',
        opacity: 0.6,
      },
      editable: {
        '&:hover': {
          textDecoration: 'underline dotted',
        },
      },
      inlineTextField: {
        '.MuiInputBase-root': {
          marginBottom: '-4px',
        },
      },
    },
  },
  menu: {
    base: {
      marginTop: '0.75rem',
      borderRadius: '0.25rem',
      border: '1px #E2E5E7',
      boxShadow: '0px 2px 12px 2px rgba(15, 30, 36, 0.12)',
      '.MuiMenu-list': {
        paddingY: '1rem',
        paddingX: '0.75rem',
      },
      '.MuiList-root': {
        display: 'flex',
        flexDirection: 'column',
        gap: '0.5rem',
      },
    },
    quickBase: {
      marginTop: '0.75rem',
      borderRadius: '0.25rem',
      border: '1px #E2E5E7',
      boxShadow: '0px 2px 12px 2px rgba(15, 30, 36, 0.12)',
    },
    listText: {
      primary: {
        fontSize: '0.875rem',
        fontWeight: '400',
        lineHeight: '1.25rem',
        color: '#052E28',
      },
      danger: {
        color: '#EC4B27',
        fontSize: '0.875rem',
        fontWeight: '400',
        lineHeight: '1.25rem',
      },
    },
    item: {
      borderRadius: '0.25rem',
      '.MuiListItemIcon-root': {
        minWidth: '2rem',
      },
    },
  },
  modal: {
    base: {
      borderRadius: '0.5rem',
      boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.15)',
      backgroundColor: WHITE_100,
    },
    title: {
      fontSize: '1.125rem',
      fontWeight: '700',
      lineHeight: '120%',
      color: '#043B34',
    },
    divider: {
      borderBottomWidth: '0.0625rem',
      opacity: 1,
      color: '#DDe0DE',
    },
    label: {
      fontSize: '0.8125rem',
      fontWeight: '500',
      lineHeight: '150%',
      color: '#043B34',
    },
    heavy: {
      fontSize: '0.8125rem',
      fontWeight: '700',
      lineHeight: '150%',
      color: '#043B34',
    },
  },
}

const StyledTableRow = styled(TableRow)(() => ({
  '&:hover': {
    backgroundColor: '#EBFAF1 !important',
  },
  '&:nth-of-type(odd)': {
    backgroundColor: WHITE_100,
  },
  '&:nth-of-type(even)': {
    backgroundColor: WHITE_100,
  },
}));

const CreateDocumentMenu = ({onClick}) => {
  const popupState = usePopupState({variant: 'popover', popupId: 'createDocumentMenu'});

  const _handleClick = (reportType) => () => {
    onClick(reportType);
    popupState.close();
  }

  return (
    <div>
      <Button variant={'contained'} startIcon={<AddIcon />} sx={styles.createButton} {...bindTrigger(popupState)}><Typography style={styles.createButtonLabel}>Create new</Typography></Button>
      <Menu {...bindMenu(popupState)} anchorOrigin={{vertical: 'bottom', horizontal: 'right'}} transformOrigin={{vertical: 'top', horizontal: 'right'}} sx={styles.menu.base}>
        <MenuItem onClick={_handleClick('ANNUAL')} sx={styles.menu.item}>
          <ListItemIcon><img src={DocumentIcon} alt={''}/></ListItemIcon>
          <ListItemText primaryTypographyProps={styles.menu.listText.primary}>Annual report</ListItemText>
        </MenuItem>
        <MenuItem onClick={_handleClick('QUARTER')} sx={styles.menu.item}>
          <ListItemIcon><img src={DocumentIcon} alt={''}/></ListItemIcon>
          <ListItemText primaryTypographyProps={styles.menu.listText.primary}>Quarterly report</ListItemText>
        </MenuItem>
        <MenuItem onClick={_handleClick('OTHER')} sx={styles.menu.item}>
          <ListItemIcon><img src={FileIcon} alt={''}/></ListItemIcon>
          <ListItemText primaryTypographyProps={styles.menu.listText.primary}>Basic document</ListItemText>
        </MenuItem>
      </Menu>
    </div>
  )
}

CreateDocumentMenu.propTypes = {
  onClick: PropTypes.func.isRequired,
}

const _getDocumentName = (reportType, base, fiscalYear, quarter) => {
  if (_.isNil(base) || _.isEmpty(base)) {
    console.warn('Document name base is empty');
  }
  switch (reportType) {
    case 'ANNUAL':
      if (_.isNil(base) || _.isEmpty(base)) {
        return `${fiscalYear} - Annual Report`;
      }
      return `${base} - ${fiscalYear} - Annual Report`;
    case 'QUARTER':
      if (_.isNil(base) || _.isEmpty(base)) {
        return `${quarter} ${fiscalYear} - Quarterly Report`;
      }
      return `${base} - ${quarter} ${fiscalYear} - Quarterly Report`;
    default:
      return base;
  }
}

const CreateDocumentModal = ({reportType, open, onClose, onError}) => {
  const {orgConfig} = useSelector((state) => state.session);
  const [loading, setLoading] = useState(false);
  const [quarter, setQuarter] = useState(spacetime.now().format('quarter'));
  const [fiscalYear, setFiscalYear] = useState(spacetime.now().format('year'));
  const [documentName, setDocumentName] = useState(_getDocumentName(reportType, _.get(orgConfig, 'companyLegalName', orgConfig?.name), fiscalYear, quarter));
  const [documentNameIsDirty, setDocumentNameIsDirty] = useState(false);
  const [documentNameError, setDocumentNameError] = useState(false);
  const [documentNameErrorMessage, setDocumentNameErrorMessage] = useState('');
  const [createDocument, {isSuccess, isError, error, reset}] = useCreateDocumentMutation();

  const _onClose = () => {
    const _quarter = spacetime.now().format('quarter');
    const _fiscalYear = spacetime.now().format('year');
    setQuarter(_quarter);
    setFiscalYear(_fiscalYear);
    setDocumentName(_getDocumentName(reportType, _.get(orgConfig, 'companyLegalName', orgConfig?.name), _fiscalYear, _quarter));
    setDocumentNameIsDirty(false);
    setDocumentNameError(false);
    setDocumentNameErrorMessage('');
    onClose();
  }

  useEffect(() => {
    if (orgConfig) {
      setDocumentName(_getDocumentName(reportType, reportType === 'OTHER' ? '' : _.get(orgConfig, 'companyLegalName', orgConfig.name), fiscalYear, quarter));
    }
  }, [orgConfig, reportType]);

  useEffect(() => {
    if (isSuccess) {
      _onClose();
      reset();
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      console.error('Error creating document', {error});
      if (_.get(error, 'data.code') === 'DOCUMENT_EXISTS') {
        setDocumentNameError(true);
        setDocumentNameErrorMessage('Filename exists. Please choose another.');
      } else {
        onError();
      }
      reset();
    }
  }, [isError]);

  const _getModalTitle = () => {
    switch (reportType) {
      case 'ANNUAL':
        return 'annual report';
      case 'QUARTER':
        return 'quarterly report';
      default:
        return 'basic document';
    }
  }

  const _handleChange = (field) => (event) => {
    const _value = event.target.value;
    switch (field) {
      case 'quarter':
        setQuarter(_value);
        if (!documentNameIsDirty) {
          setDocumentName(_getDocumentName(reportType, _.get(orgConfig, 'companyLegalName', orgConfig.name), fiscalYear, _value))
        }
        break;
      case 'fiscalYear':
        setFiscalYear(_value);
        if (!documentNameIsDirty) {
          setDocumentName(_getDocumentName(reportType, _.get(orgConfig, 'companyLegalName', orgConfig.name), _value, quarter))
        }
        break;
      default:
        setDocumentName(_value);
        setDocumentNameIsDirty(true);
    }
  }

  const _renderPeriod = () => {
    if (_.includes(['ANNUAL', 'QUARTER'], reportType)) {
      return (
        <Stack direction={'row'} spacing={'1rem'}>
          <Stack direction={'column'} spacing={'0.5rem'}>
            <Typography style={styles.modal.label}>Fiscal year</Typography>
            <FormControl>
              <Select
                id="createDocument-fiscalYear-select"
                value={fiscalYear}
                onChange={_handleChange('fiscalYear')}
              >
                {_.map(YEARS, _year => <MenuItem key={_year} value={_year}>{_year}</MenuItem>)}
              </Select>
            </FormControl>
          </Stack>
          {reportType === 'QUARTER' && (
            <Stack direction={'column'} spacing={'0.5rem'}>
              <Typography style={styles.modal.label}>Quarter</Typography>
              <FormControl>
                <Select
                  id="createDocument-quarter-select"
                  value={quarter}
                  onChange={_handleChange('quarter')}
                >
                  {_.map(QUARTERS, _quarter => <MenuItem key={_quarter} value={_quarter}>{_quarter}</MenuItem>)}
                </Select>
              </FormControl>
            </Stack>
          )}
        </Stack>
      );
    }
  }

  const _renderDocumentName = () => {
    return (
      <Stack direction={'column'} spacing={'0.5rem'}>
        <Typography style={styles.modal.label}>Document name</Typography>
        <TextField id="createDocument-documentName" variant="outlined" value={documentName} onChange={_handleChange('documentName')} error={documentNameError} helperText={documentNameErrorMessage} />
      </Stack>
    );
  }

  const _createDocument = async () => {
    setDocumentNameError(false);
    setDocumentNameErrorMessage('');
    setLoading(true);
    await createDocument({
      name: documentName,
      type: reportType,
      status: 'DRAFT',
      fiscalYear,
      quarter,
    });
    setLoading(false);
  }

  const _validateInputs = () => {
    switch (reportType) {
      case 'ANNUAL':
        return !_.isEmpty(fiscalYear) && !_.isEmpty(documentName);
      case 'QUARTER':
        return !_.isEmpty(fiscalYear) && !_.isEmpty(quarter) && !_.isEmpty(documentName);
      default:
        return !_.isEmpty(documentName);
    }
  }

  return (
    <Modal open={open} onClose={_onClose}>
      <Box position={'absolute'} sx={{left: '50%', top: '50%', transform: 'translate(-50%, -50%)'}}>
        <Box width={'28rem'} minWidth={'28rem'} maxWidth={'28rem'}>
          <Stack direction={'column'} sx={styles.modal.base}>
            <Stack direction={'row'} paddingY={'1rem'} paddingLeft={'1.5rem'} paddingRight={'1rem'} flex={1} justifyContent={'space-between'} alignItems={'center'}>
              <Typography sx={styles.modal.title}>Create {_getModalTitle()}</Typography>
              <IconButton onClick={_onClose}><CloseIcon /></IconButton>
            </Stack>
            <Divider orientation={'horizontal'} sx={styles.modal.divider} />
            <Stack direction={'column'} paddingTop={'1.25rem'} paddingBottom={'1.5rem'} paddingX={'1.5rem'} spacing={'1.25rem'}>
              {_renderPeriod()}
              {_renderDocumentName()}
            </Stack>
            <Divider orientation={'horizontal'} sx={styles.modal.divider} />
            <Stack direction={'row'} paddingY={'1rem'} paddingLeft={'1.5rem'} paddingRight={'1rem'} flex={1} justifyContent={'flex-end'} alignItems={'center'} spacing={'0.5rem'}>
              <Button variant={'outlined'} sx={styles.cancelButton} onClick={_onClose}><Typography style={styles.cancelButtonLabel}>Cancel</Typography></Button>
              <LoadingButton variant={'contained'} sx={{...styles.createButton, width: '9.3125rem'}} onClick={_createDocument} disabled={!_validateInputs()} loading={loading} loadingIndicator={<Loading loading={loading} loadingProps={{color: BLACK_100}} />}><Typography style={styles.createButtonLabel}>Create document</Typography></LoadingButton>
            </Stack>
          </Stack>
        </Box>
      </Box>
    </Modal>
  )
}

CreateDocumentModal.propTypes = {
  reportType: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
}

const DeleteDocumentModal = ({rowData, open, onClose, onSuccess, onError}) => {
  const [loading, setLoading] = useState(false);
  const [deleteDocument, {isSuccess, isError, reset}] = useDeleteDocumentMutation();

  useEffect(() => {
    if (isSuccess) {
      onSuccess();
      onClose();
      reset();
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      onError();
      reset();
    }
  }, [isError]);

  const _deleteDocument = async () => {
    setLoading(true);
    await deleteDocument({
      documentId: rowData.documentId,
    });
    setLoading(false);
  }

  return (
    <Modal open={open} onClose={onClose}>
      <Box position={'absolute'} sx={{left: '50%', top: '50%', transform: 'translate(-50%, -50%)'}}>
        <Box width={'28rem'} minWidth={'28rem'} maxWidth={'28rem'}>
          <Stack direction={'column'} sx={styles.modal.base}>
            <Stack direction={'row'} paddingY={'1rem'} paddingLeft={'1.5rem'} paddingRight={'1rem'} flex={1} justifyContent={'space-between'} alignItems={'center'}>
              <Typography sx={styles.modal.title}>Delete file</Typography>
              <IconButton onClick={onClose}><CloseIcon /></IconButton>
            </Stack>
            <Divider orientation={'horizontal'} sx={styles.modal.divider} />
            <Stack direction={'column'} paddingTop={'1.25rem'} paddingBottom={'1.5rem'} paddingX={'1.5rem'} spacing={'1rem'}>
              <Typography component={'div'} style={styles.modal.label}>You’re about to delete the file <Typography component={'span'} style={styles.modal.heavy}>{rowData.name}</Typography>.</Typography>
              <Typography style={styles.modal.label}>This action is permanent and can’t be undone.</Typography>
            </Stack>
            <Divider orientation={'horizontal'} sx={styles.modal.divider} />
            <Stack direction={'row'} paddingY={'1rem'} paddingLeft={'1.5rem'} paddingRight={'1rem'} flex={1} justifyContent={'flex-end'} alignItems={'center'} spacing={'0.5rem'}>
              <Button variant={'outlined'} sx={styles.cancelButton} onClick={onClose}><Typography style={styles.cancelButtonLabel}>Cancel</Typography></Button>
              <LoadingButton variant={'contained'} sx={{...styles.createButton, width: '9.3125rem'}} onClick={_deleteDocument} loading={loading} loadingIndicator={<Loading loading={loading} loadingProps={{color: BLACK_100}} />}><Typography style={styles.createButtonLabel}>Delete document</Typography></LoadingButton>
            </Stack>
          </Stack>
        </Box>
      </Box>
    </Modal>
  )
}

DeleteDocumentModal.propTypes = {
  rowData: PropTypes.shape({
    documentId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
}

const QuickActionMenu = ({rowData, onDocumentClose, reset, onResetComplete, showDocumentDeleteModal, showRenameFile, closeMenu, oncloseMenuComplete}) => {
  const navigate = useNavigate();
  const popupState = usePopupState({variant: 'popover', popupId: 'createDocumentMenu'});
  const [status, setStatus] = useState(rowData.status);

  useEffect(() => {
    setStatus(rowData.status);
  }, [rowData]);

  useEffect(() => {
    setStatus(rowData.status);
    onResetComplete();
  }, [reset]);

  useEffect(() => {
    popupState.close();
    oncloseMenuComplete();
  }, [closeMenu])

  const _handleDocumentClose = () => {
    const _newStatus = status === 'FINAL' ? 'DRAFT' : 'FINAL';
    setStatus(_newStatus);
    onDocumentClose(_newStatus);
  }

  const _showRenameFile = () => {
    popupState.close();
    showRenameFile();
  }

  return (
    <div>
      <IconButton {...bindTrigger(popupState)}>
        <MoreHoriz />
      </IconButton>
      <Menu {...bindMenu(popupState)} anchorOrigin={{vertical: 'bottom', horizontal: 'right'}} transformOrigin={{vertical: 'top', horizontal: 'right'}} sx={styles.menu.quickBase}>
        <MenuItem sx={styles.menu.item} onClick={() => navigate(`/documents/${rowData.documentId}`)}>
          <ListItemText primaryTypographyProps={styles.menu.listText.primary}>Open</ListItemText>
        </MenuItem>
        <Divider orientation={'horizontal'} sx={styles.modal.divider} />
        <MenuItem sx={styles.menu.item}>
          <Stack direction={'row'} alignItems={'center'} gap={'1.5rem'}>
            <ListItemText primaryTypographyProps={styles.menu.listText.primary}>Set status to closed</ListItemText>
            <Switch checked={status === 'FINAL'} size={'small'} onChange={_handleDocumentClose} />
          </Stack>
        </MenuItem>
        <Divider orientation={'horizontal'} sx={styles.modal.divider} />
        <MenuItem sx={styles.menu.item} onClick={_showRenameFile}>
          <ListItemText primaryTypographyProps={styles.menu.listText.primary}>Rename</ListItemText>
        </MenuItem>
        <MenuItem sx={styles.menu.item} onClick={showDocumentDeleteModal}>
          <ListItemText primaryTypographyProps={styles.menu.listText.danger}>Delete</ListItemText>
        </MenuItem>
      </Menu>
    </div>
  )
}

QuickActionMenu.propTypes = {
  rowData: PropTypes.shape({
    status: PropTypes.string.isRequired,
    documentId: PropTypes.string.isRequired,
  }).isRequired,
  onDocumentClose: PropTypes.func.isRequired,
  reset: PropTypes.bool.isRequired,
  onResetComplete: PropTypes.func.isRequired,
  showDocumentDeleteModal: PropTypes.func.isRequired,
  showRenameFile: PropTypes.func.isRequired,
  closeMenu: PropTypes.bool.isRequired,
  oncloseMenuComplete: PropTypes.func.isRequired,
}

const DocumentRow = memo(({idx, rowData, onError, onEditIsOpen, editIsOpen}) => {
  const navigate = useNavigate();
  const [editDocumentName, setEditDocumentName] = useState(false);
  const [documentName, setDocumentName] = useState(_.get(rowData, 'name', ''));
  const [newDocumentName, setNewDocumentName] = useState(_.get(rowData, 'name', ''));
  const [documentStatus, setDocumentStatus] = useState(rowData.status);
  const [resetQuickMenu, setResetQuickMenu] = useState(false);
  const [closeQuickMenu, setCloseQuickMenu] = useState(false);
  const [showDocumentDeleteModal, setShowDocumentDeleteModal] = useState(false);
  const [updateDocument, {isSuccess, isError, error, reset}] = useUpdateDocumentMutation();
  const inputRef = useRef(null);

  const handleClickOutside = event => {
    if (inputRef.current && !inputRef.current.contains(event.target)) {
      setEditDocumentName(false);
    }
  }

  useEffect(() => {
    if (editDocumentName) {
      if (inputRef.current) {
        inputRef.current.querySelector('input').select();
      }
      onEditIsOpen(true);
      document.addEventListener('click', handleClickOutside, true);
      return () => {
        document.removeEventListener('click', handleClickOutside, true);
      };
    } else {
      onEditIsOpen(false);
    }
  }, [editDocumentName]);

  useEffect(() => {
    setDocumentName(rowData.name);
    setNewDocumentName(rowData.name);
    setDocumentStatus(rowData.status);
  }, [rowData]);

  useEffect(() => {
    if (isSuccess) {
      setCloseQuickMenu(true);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      setDocumentName(rowData.name);
      setDocumentStatus(rowData.status);
      setNewDocumentName(rowData.name);
      setResetQuickMenu(true);
      if (_.get(error, 'data.code') === 'DOCUMENT_EXISTS') {
        onError('Filename exists. Please choose another.');
      } else {
        onError();
      }
      reset();
    }
  }, [isError]);

  const _renderFileType = line => {
    switch (line.type) {
      case 'ANNUAL':
        if (_.has(line, 'fiscalYear')) {
          return `Annual report (${line.fiscalYear})`;
        }
        return 'Annual report';
      case 'QUARTER':
        if (_.has(line, 'fiscalYear') && _.has(line, 'quarter')) {
          return `Quarterly report (${line.quarter} ${line.fiscalYear})`;
        } else if (_.has(line, 'fiscalYear')) {
          return `Quarterly report (${line.fiscalYear})`;
        }
        return 'Quarterly report';
      default:
        return 'Basic document';
    }
  }

  const _getChipDetails = status => {
    switch (status) {
      case 'FINAL':
        return {
          label: 'Closed - Filled',
          variant: 'closed',
        }
      default:
        return {
          label: 'Open - Draft',
          variant: 'open',
        }
    }
  }

  const _editDocumentName = event => {
    event.stopPropagation();
    setEditDocumentName(!editDocumentName);
    onEditIsOpen(false);
  }

  const _handleDocumentNameChange = event => {
    setNewDocumentName(event.target.value);
  }

  const _updateDocumentName = async event => {
    setDocumentName(newDocumentName);
    _editDocumentName(event);
    await updateDocument({documentId: rowData.documentId, data: {name: newDocumentName.trim()}});
  }

  const _handleCellClick = () => {
    if (!editDocumentName && !editIsOpen) {
      navigate(`/documents/${rowData.documentId}`);
    }
  }

  const _handleDocumentClose = async (status) => {
    setDocumentStatus(status);
    await updateDocument({documentId: rowData.documentId, data: {status}});
  }

  const _handleDeleteDocumentModalSuccess = () => {
    setCloseQuickMenu(true);
  }

  const _handleKeyPress = async (event) => {
    switch (event.key) {
      case 'Enter':
        setDocumentName(newDocumentName);
        setEditDocumentName(false);
        await updateDocument({documentId: rowData.documentId, data: {name: newDocumentName}});
        break;
      case 'Escape':
        setEditDocumentName(false);
        break;
      default:
        break;
    }
  }

  return (
    <React.Fragment>
      <StyledTableRow key={idx} sx={{cursor: editDocumentName ? 'default' : 'pointer'}}>
        <TableCell sx={{width: TABLE_HEADERS[0].width, maxWidth: TABLE_HEADERS[0].maxWidth}} onClick={_handleCellClick}>
          <Stack direction={'row'} spacing={1} alignItems={'center'}>
            <img src={DocumentIcon} alt={''}/>
            {!editDocumentName && <Typography noWrap sx={styles.table.row.label}>{documentName}</Typography>}
            {editDocumentName && (
              <TextField
                ref={inputRef}
                size={'small'}
                inputRef={input => input && input.focus()}
                sx={styles.table.row.inlineTextField}
                variant={'standard'}
                value={newDocumentName}
                onChange={_handleDocumentNameChange}
                fullWidth
                onKeyUp={_handleKeyPress}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="confirm name change"
                        onClick={_updateDocumentName}
                        edge="end"
                        size={'small'}
                      >
                        <CheckIcon fontSize={'inherit'} />
                      </IconButton>
                      <IconButton
                        aria-label="confirm name change"
                        onClick={_editDocumentName}
                        edge="end"
                        size={'small'}
                      >
                        <CloseIcon fontSize={'inherit'} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          </Stack>
        </TableCell>
        <TableCell sx={{width: TABLE_HEADERS[1].width}} onClick={_handleCellClick}>
          <Typography sx={styles.table.row.label}>{_renderFileType(rowData)}</Typography>
        </TableCell>
        <TableCell sx={{width: TABLE_HEADERS[2].width}} onClick={_handleCellClick}>
          <Chip size={'small'} {..._getChipDetails(documentStatus)} />
        </TableCell>
        <TableCell sx={{width: TABLE_HEADERS[3].width}} onClick={_handleCellClick}>
          <Stack direction={'column'}>
            <Typography sx={styles.table.row.label}><TimeAgo timestamp={rowData.updatedAt} /></Typography>
            <Typography sx={styles.table.row.secondaryLabel}>By {rowData.updatedByName}</Typography>
          </Stack>
        </TableCell>
        <TableCell sx={{width: '4.5rem'}}>
          <QuickActionMenu
            rowData={rowData}
            onDocumentClose={_handleDocumentClose}
            reset={resetQuickMenu}
            onResetComplete={() => setResetQuickMenu(false)}
            showDocumentDeleteModal={() => setShowDocumentDeleteModal(true)}
            showRenameFile={() => setEditDocumentName(true)}
            closeMenu={closeQuickMenu}
            oncloseMenuComplete={() => setCloseQuickMenu(false)}
          />
        </TableCell>
      </StyledTableRow>
      <DeleteDocumentModal rowData={rowData} open={showDocumentDeleteModal} onClose={() => setShowDocumentDeleteModal(false)} onSuccess={_handleDeleteDocumentModalSuccess} onError={onError} />
    </React.Fragment>
  )
});

DocumentRow.displayName = 'DocumentRow';
DocumentRow.propTypes = {
  idx: PropTypes.number.isRequired,
  rowData: PropTypes.shape({
    documentId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    updatedAt: PropTypes.string.isRequired,
    updatedByName: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    fiscalYear: PropTypes.string,
    quarter: PropTypes.string,
    status: PropTypes.string,
  }),
  onError: PropTypes.func.isRequired,
  onEditIsOpen: PropTypes.func.isRequired,
  editIsOpen: PropTypes.bool.isRequired,
}

class DocumentsPage extends AppPage {
  constructor (props) {
    super(props);
    this.state = {
      showCreateReportModal: false,
      reportType: '',
      documentNameIsDirty: false,
    }
  }

  _onClick = (reportType) => {
    this.setState({
      reportType,
      showCreateReportModal: true,
    });
  }

  _resetCreateReportModal = () => {
    this.setState({
      reportType: '',
      showCreateReportModal: false,
    });
  }

  getPageTitle = () => 'Documents';

  renderBodyContent = () => {
    return (
      <React.Fragment>
        <DocumentsPageComponent flags={this.props.flags} reportType={this.state.reportType} showCreateReportModal={this.state.showCreateReportModal} resetCreateReportModal={this._resetCreateReportModal} />
      </React.Fragment>
    )
  }

  renderCustomHeaderContent = () => {
    if (!this.props.flags.multipleDocuments) {
      return null;
    }
    return (
      <Stack direction={'row'} flex={1} justifyContent={'flex-end'}>
        <CreateDocumentMenu onClick={this._onClick} />
      </Stack>
    )
  }
}

const DocumentsPageComponent = ({flags, reportType, showCreateReportModal, resetCreateReportModal}) => {
  const cardStyles = {
    my: 2,
    display: 'flex',
    borderRadius: '8px',
    border: '1px solid #D7D8DA',
    boxShadow: '0',
    textDecoration: 'none',
  };

  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('updatedAt');
  const [sortedTableData, setSortedTableData] = useState([]);
  const [skip, setSkip] = useState(true);
  const {currentOrg, accessToken} = useSelector((state) => state.session);
  const {data, isLoading, isError, isSuccess} = useGetDocumentsQuery({}, {skip, refetchOnMountOrArgChange: true, pollingInterval: 30000});
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [editIsOpen, setEditIsOpen] = useState(false);

  useEffect(() => {
    setSkip(_.isNil(currentOrg) || _.isEmpty(currentOrg) || _.isNil(accessToken) || _.isEmpty(accessToken));
  }, [currentOrg, accessToken]);

  useEffect(() => {
    setSortedTableData(sortRecords(data || [], order, orderBy));
  }, [data, order, orderBy]);

  if (!flags.multipleDocuments) {
    return (
      <CommonPageWrapper>
        <Stack direction={'column'} flex={1} justifyContent={'center'}>
          <Box mt={1.5}>
            <Typography color='#052E28' fontWeight={500}>All documents</Typography>

            <Card component={Link} to='/document' variant='outlined' sx={{...cardStyles, minHeight: '80px'}}>
              <Box display='flex' alignItems='center' justifyContent='space-between' width='100%' px={3}>
                <Stack direction='row' spacing={2} alignItems='center'>
                  <img
                    alt={''}
                    src={DocumentIcon}
                    width={20}
                    height={20}
                  />

                  <Typography fontSize="16px" fontWeight={700}>Annual Report</Typography>
                </Stack>

                <Button
                  component={Link}
                  to='/document'
                  variant="outlined"
                  sx={{borderRadius: '8px'}}
                >
                  Open
                </Button>
              </Box>
            </Card>
          </Box>
        </Stack>
      </CommonPageWrapper>
    )
  }

  const _onShowError = (errorMessage = 'There was an error processing your request. Please try again later.') => {
    setErrorMessage(errorMessage);
    setShowError(true);
  }

  const _onHideError = () => {
    setErrorMessage('');
    setShowError(false);
  }

  const _handleRequestSort = property => () => {
    const _isAsc = orderBy === property && order === 'asc';
    setOrder(_isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  }

  const _renderError = () => {
    if (!isLoading && isError) {
      return (
        <Error hasError={isError} message={'There was an error processing your request. Please try again later.'} />
      )
    }
  }

  const _renderTable = () => {
    if (!isLoading && isSuccess) {
      return (
        <Stack direction={'column'} spacing={'0.75rem'}>
          <TableContainer sx={styles.table.container}>
            <Table>
              <TableHead cellSpacing={'2.5rem'}>
                <TableRow sx={{borderRadius: '0.25rem', border: '1px #DDE0DE', background: '#F3F4F4'}}>
                  {_.map(TABLE_HEADERS, _header => (
                    <TableCell key={_header.id} variant={'head'} sortDirection={orderBy === _header.id ? order : false} sx={{...styles.table.header.cell, width: _header.width}}>
                      <TableSortLabel
                        active={orderBy === _header.id}
                        direction={orderBy === _header.id ? order : 'asc'}
                        onClick={_handleRequestSort(_header.id)}>
                        <Typography sx={styles.table.header.label}>{_header.label}</Typography>
                        {orderBy === _header.id
                          ? (
                            <Box component={'span'} sx={visuallyHidden}>
                              {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </Box>
                            )
                          : null}
                      </TableSortLabel>
                    </TableCell>
                  ))}
                  <TableCell sx={{...styles.table.header.cell, width: '4.5rem'}} />
                </TableRow>
              </TableHead>
            </Table>
          </TableContainer>
          <TableContainer sx={{maxHeight: `calc(100vh - ${APP_HEADER_HEIGHT} - ${FOOTER_HEIGHT} - 5.075rem - 2.06rem - 1rem - 1.5rem - 2.28125rem - 0.75rem - 2rem)`}}>
            <Table stickyHeader>
              <TableBody sx={{marginTop: '0.75rem'}}>
                {_.map(sortedTableData, (_data, _idx) => {
                  return <DocumentRow key={_idx} idx={_idx} rowData={_data} onError={_onShowError} onEditIsOpen={setEditIsOpen} editIsOpen={editIsOpen} />
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
      )
    }
  }

  return (
    <CommonPageWrapper height={'100%'} pt={'2.06rem'}>
      <Stack direction={'row'} flex={1} height={'100%'}>
        <Stack direction={'column'} flex={1} spacing={'1.5rem'}>
          <Typography sx={styles.recentDocuments.label}>Recent documents</Typography>
          <Loading loading={isLoading} />
          {_renderError()}
          {_renderTable()}
        </Stack>
      </Stack>
      <CreateDocumentModal reportType={reportType} open={showCreateReportModal} onClose={resetCreateReportModal} onError={_onShowError} />
      <Snackbar open={showError} autoHideDuration={6000} onClose={_onHideError} anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}>
        <Alert
          onClose={_onHideError}
          severity={'error'}>
          {errorMessage}
        </Alert>
      </Snackbar>
    </CommonPageWrapper>
  )
}

const mapStateToProps = (state) => {
  return {
    session: state.session,
  }
}

DocumentsPageComponent.propTypes = {
  flags: PropTypes.shape({
    multipleDocuments: PropTypes.bool.isRequired,
  }),
  reportType: PropTypes.string.isRequired,
  showCreateReportModal: PropTypes.bool.isRequired,
  resetCreateReportModal: PropTypes.func.isRequired,
}

export default withLDConsumer()(connect(mapStateToProps)(withRequiredAuthInfo(DocumentsPage)));
