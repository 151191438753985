import React, {useState, useEffect, useCallback, useRef} from 'react';
import {useThreads} from '../../liveblocks.config';
import {Thread} from '@liveblocks/react-comments';
import {
  getCommentHighlight,
  getCommentHighlightContent,
  removeCommentHighlight,
  useHighlightEventListener,
} from './comment-utils';
import {Box, Typography, styled} from '@mui/material';
import DOMPurify from 'dompurify';
import {BLACK_100, GREY_90} from '../../theme';
import PropTypes from 'prop-types';
import {truncate} from '../../util';

const activeCardBackground = '#FFFBE9'

const StyledThread = styled(Thread)(() => ({
  '&[data-active="true"] .lb-comment': {
    background: activeCardBackground,
  },
  '&[data-active="true"] .lb-composer-editor': {
    background: activeCardBackground,
  },
  '&[data-active="true"] .lb-thread-composer': {
    background: activeCardBackground,
  },
  '.lb-comment-author': {
    fontSize: '14px',
    fontWeight: 500,
  },
}));

const styles = {
  noCommentsContainer: {
    pt: 40,
    px: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  noCommentsHeader: {
    color: BLACK_100,
    fontSize: '1.2rem',
    fontWeight: 500,
    textAlign: 'center',
  },
  noCommentsSubHeader: {
    color: GREY_90,
    fontSize: '0.85rem',
    mt: 1,
    textAlign: 'center',
  },
}

const DocumentThreads = ({editor, readOnlySave}) => {
  const {threads} = useThreads();
  const [activeThreadId, setActiveThreadId] = useState(null);
  const containerRef = useRef(null);

  const sortedAndFilteredThreads = threads
    .sort(sortThreads)
    .filter(t => !t.metadata.resolved);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        setActiveThreadId(null);
        removeAllSelectedStates();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const removeAllSelectedStates = () => {
    const selectedComments = document.querySelectorAll('[data-selected="true"]');
    selectedComments.forEach(comment => {
      comment.dataset.selected = 'false';
    });
  };

  return (
    <Box ref={containerRef}>
      {sortedAndFilteredThreads.length > 0
        ? (
            sortedAndFilteredThreads
              .map((thread) => (
                <CustomThread
                  key={thread.id}
                  thread={thread}
                  editor={editor}
                  readOnlySave={readOnlySave}
                  activeThreadId={activeThreadId}
                  setActiveThreadId={setActiveThreadId}
                  removeAllSelectedStates={removeAllSelectedStates}
                />
              ))
          )
        : (
          <Box sx={styles.noCommentsContainer}>
            <Typography sx={styles.noCommentsHeader}>
              There are no comments...
            </Typography>
            <Typography sx={styles.noCommentsSubHeader}>
              Highlight any text in the report and click &apos;Comment&apos; to start a discussion.
            </Typography>
          </Box>
          )
      }
    </Box>
  );
}

DocumentThreads.propTypes = {
  editor: PropTypes.object.isRequired,
  readOnlySave: PropTypes.func.isRequired,
}

const CustomThread = ({editor, thread, readOnlySave, activeThreadId, setActiveThreadId, removeAllSelectedStates}) => {
  const threadRef = useRef(null);
  const isActive = activeThreadId === thread.metadata.highlightId;

  useHighlightEventListener((highlightId, isClick) => {
    if (highlightId === thread.metadata.highlightId) {
      setActiveThreadId(highlightId);
      if (isClick && threadRef.current && !isInViewport(threadRef.current)) {
        threadRef.current.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
      }
    }
  });

  const handleThreadDelete = useCallback(
    (thread) => {
      removeCommentHighlight(editor, thread.metadata.highlightId);
      readOnlySave();
    },
    [editor],
  );

  const handleThreadResolved = useCallback(
    (resolved) => {
      removeCommentHighlight(editor, thread.metadata.highlightId);
      readOnlySave();
    },
    [editor],
  );

  const quoteHtml = getCommentHighlightContent(thread.metadata.highlightId);
  const sanitizedHtml = DOMPurify.sanitize(quoteHtml);

  const handleThreadClick = useCallback(() => {
    const highlight = getCommentHighlight(editor, thread.metadata.highlightId);

    if (highlight) {
      const {node} = highlight;
      let foundPos = null;
      let pageId = null;

      // Find the position of the highlighted node
      editor.state.doc.nodesBetween(0, editor.state.doc.content.size, (n, pos) => {
        if (n === node) {
          foundPos = pos;
          return false;
        }
      });

      // If we found the highlighted node, now find its parent page
      if (foundPos !== null) {
        const resolvedPos = editor.state.doc.resolve(foundPos);

        for (let depth = resolvedPos.depth; depth > 0; depth--) {
          const parentNode = resolvedPos.node(depth);
          if (parentNode.type.name === 'page') {
            pageId = parentNode.attrs.id;
            break;
          }
        }

        if (pageId) {
          const commentElement = document.querySelector(`[data-highlight-id="${thread.metadata.highlightId}"]`);

          if (commentElement) {
            removeAllSelectedStates();
            commentElement.scrollIntoView({behavior: 'smooth', block: 'start'});
            requestAnimationFrame(() => {
              commentElement.dataset.selected = 'true';
            });
          }
        }
      }
    }

    setActiveThreadId(thread.metadata.highlightId);
  }, [editor, thread.metadata.highlightId, setActiveThreadId]);

  const getBackgroundColor = () => {
    return isActive ? activeCardBackground : '#fff';
  };

  const isInViewport = (element) => {
    const rect = element.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  };

  useEffect(() => {
    const currentThreadRef = threadRef.current;
    if (currentThreadRef) {
      currentThreadRef.addEventListener('click', handleThreadClick, true);
    }
    return () => {
      if (currentThreadRef) {
        currentThreadRef.removeEventListener('click', handleThreadClick, true);
      }
    };
  }, [handleThreadClick]);

  return (
    <div className="hide-collaboration-cursor" ref={threadRef}>
      <Box
        sx={{
          background: getBackgroundColor(),
          mt: 1,
          border: '1px solid #DDE0DE',
          transition: 'transform 0.3s ease, box-shadow 0.3s ease',
          boxShadow: isActive ? '0 2px 8px rgb(0 0 0 / 5%), 0 4px 20px rgb(0 0 0 / 5%)' : '0 2px 8px rgb(0 0 0 / 5%)',
          borderRadius: '0.4rem',
          overflow: 'hidden',
          width: '300px',
        }}
        data-active={isActive}
        data-highlight-id={thread.metadata.highlightId}
      >
        {quoteHtml
          ? (
              <Box
                sx={{
                  display: 'inline-block',
                  margin: '1rem 1rem 0.125rem',
                  borderLeft: '2px solid #F5C15C',
                  padding: '0.125rem 0.5rem',
                  fontSize: '12px',
                }}
                dangerouslySetInnerHTML={{
                  __html: truncate(sanitizedHtml, 40),
                }}
              />
            )
          : (
            <Box
              sx={{
                display: 'inline-block',
                margin: '1rem 1rem 0.125rem',
                padding: '0.125rem 0.5rem',
              }}
            >
              <Typography sx={{fontStyle: 'italic', color: GREY_90, fontSize: '11px'}}>
                The text was deleted
              </Typography>
            </Box>
            )
        }
        <StyledThread
          autoFocus={true}
          thread={thread}
          onThreadDelete={handleThreadDelete}
          onResolvedChange={handleThreadResolved}
          indentCommentContent={false}
          data-active={isActive}
          overrides={{THREAD_COMPOSER_PLACEHOLDER: 'Reply...'}}
        />
      </Box>
    </div>
  );
};

CustomThread.propTypes = {
  editor: PropTypes.object.isRequired,
  readOnlySave: PropTypes.func.isRequired,
  thread: PropTypes.shape({
    metadata: PropTypes.shape({
      highlightId: PropTypes.string.isRequired,
    }),
  }),
  activeThreadId: PropTypes.string,
  setActiveThreadId: PropTypes.func.isRequired,
  removeAllSelectedStates: PropTypes.func.isRequired,
}

const sortThreads = (a, b) => {
  if (a.metadata.resolved) {
    return 1;
  }

  if (b.metadata.resolved) {
    return -1;
  }

  if (a.createdAt > b.createdAt) {
    return -1;
  }

  if (a.createdAt < b.createdAt) {
    return 1;
  }

  return 0;
}

export default DocumentThreads;
