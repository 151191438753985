import React, {useEffect, useState} from 'react'
import ReactDOM from 'react-dom/client'
import 'bootstrap/dist/css/bootstrap.min.css'
import {RedirectToLogin, RequiredAuthProvider} from '@propelauth/react'
import {BrowserRouter} from 'react-router-dom'
import {Provider} from 'react-redux'
import store from './store'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import {asyncWithLDProvider} from 'launchdarkly-react-client-sdk';
import {persistStore} from 'redux-persist';
import {PersistGate} from 'redux-persist/integration/react';
import {init as initFullStory} from '@fullstory/browser';
import _ from 'lodash';

// Tables
import 'handsontable/dist/handsontable.full.min.css';
import {registerAllModules} from 'handsontable/registry';

initFullStory({orgId: process.env.REACT_APP_FS_ORG_ID, devMode: !_.includes(['staging', 'production', 'ci-test'], process.env.REACT_APP_ENVIRONMENT)});

const persistor = persistStore(store)

registerAllModules();

function Main () {
  const [LDProvider, setLDProvider] = useState(null);

  useEffect(() => {
    async function initializeLDProvider () {
      const provider = await asyncWithLDProvider({
        clientSideID: process.env.REACT_APP_LD_CLIENTSIDE_ID,
      });
      setLDProvider(() => provider);
    }

    initializeLDProvider();
  }, []);

  return LDProvider
    ? (
    <React.StrictMode>
      <RequiredAuthProvider
        authUrl={process.env.REACT_APP_PROPELAUTH_AUTH_URL}
        defaultDisplayIfLoggedOut={
          <RedirectToLogin
            postLoginRedirectUrl={window.location.href}
          />
        }
        displayIfLoggedOut={
          <RedirectToLogin
            postLoginRedirectUrl={window.location.href}
          />
        }
      >
        <Provider store={store}>
          <PersistGate persistor={persistor}>
            <BrowserRouter>
              <LDProvider>
                <App/>
              </LDProvider>
            </BrowserRouter>
          </PersistGate>
        </Provider>
      </RequiredAuthProvider>
    </React.StrictMode>
      )
    : (
    <div>Loading...</div>
      )
}

ReactDOM.createRoot(document.getElementById('root')).render(<Main />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
export {REPORTING_STANDARDS} from './constants';
export {TOTAL_FSLIS} from './constants';
export {INDENT_UNITS} from './constants';
export {REPORTS} from './constants';
export {FSLI_TYPES} from './constants';
