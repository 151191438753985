import {createSlice} from '@reduxjs/toolkit'

const INITIAL_STATE = {
  currentOrg: {},
  orgConfig: {},
  orgs: [],
  accessToken: null,
  showInitialCopilotScreen: true,
};

export const sessionSlice = createSlice({
  name: 'session',
  initialState: INITIAL_STATE,
  reducers: {
    setOrg: (state, action) => {
      state.currentOrg = action.payload.org
    },
    setOrgConfig: (state, action) => {
      state.orgConfig = action.payload.orgConfig
    },
    setOrgs: (state, action) => {
      state.orgs = action.payload.orgs
    },
    setAccessToken: (state, action) => {
      state.accessToken = action.payload.accessToken;
    },
    setShowInitialCopilotScreen: (state, action) => {
      state.showInitialCopilotScreen = action.payload.showInitialCopilotScreen;
    },
    reset: () => INITIAL_STATE,
  },
})

// Action creators are generated for each case reducer function
export const {reset, setOrg, setOrgConfig, setOrgs, setAccessToken, setShowInitialCopilotScreen} = sessionSlice.actions;

export default sessionSlice.reducer;
