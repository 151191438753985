import {Fragment, Slice} from '@tiptap/pm/model';
import {NodeSelection, TextSelection} from '@tiptap/pm/state';
import {canSplit} from '@tiptap/pm/transform';
import {getNodeType} from '@tiptap/core';
import {defaultBlockAt} from '@tiptap/react';
import {getId} from './page/page-utils';

export const getSplittedAttributes = (extensionAttributes, typeName, attributes) => {
  return Object.fromEntries(
    Object.entries(attributes).filter(([name]) => {
      const extensionAttribute = extensionAttributes.find((item) => {
        return item.type === typeName && item.name === name;
      });

      if (!extensionAttribute) {
        return false;
      }

      return extensionAttribute.attribute.keepOnSplit;
    }),
  );
}

export const ensureMarks = (state, splittableMarks) => {
  const marks = state.storedMarks || (state.selection.$to.parentOffset && state.selection.$from.marks());

  if (marks) {
    const filteredMarks = marks.filter((mark) => splittableMarks?.includes(mark.type.name));

    state.tr.ensureMarks(filteredMarks);
  }
}

export const splitBlock = ({keepMarks = true} = {}) => ({tr, state, dispatch, editor}) => {
  const {selection, doc} = tr;
  const {$from, $to} = selection;
  const extensionAttributes = editor.extensionManager.attributes;
  const newAttributes = getSplittedAttributes(extensionAttributes, $from.node().type.name, $from.node().attrs);

  if (newAttributes.id) {
    newAttributes.id = getId();
  }

  if (newAttributes.extend === 'true') {
    newAttributes.extend = 'false';
  }

  if (selection instanceof NodeSelection && selection.node.isBlock) {
    if (!$from.parentOffset || !canSplit(doc, $from.pos)) {
      return false;
    }

    if (dispatch) {
      if (keepMarks) {
        ensureMarks(state, editor.extensionManager.splittableMarks);
      }

      tr.split($from.pos).scrollIntoView();
    }

    return true;
  }

  if (!$from.parent.isBlock) {
    return false;
  }

  if (dispatch) {
    const atEnd = $to.parentOffset === $to.parent.content.size;

    if (selection instanceof TextSelection) {
      tr.deleteSelection();
    }

    const deflt = $from.depth === 0 ? undefined : defaultBlockAt($from.node(-1).contentMatchAt($from.indexAfter(-1)));

    let types = deflt
      ? [
          {
            type: deflt,
            attrs: newAttributes,
          },
        ]
      : undefined;

    let can = canSplit(tr.doc, tr.mapping.map($from.pos), 1, types);

    if (!types && !can && canSplit(tr.doc, tr.mapping.map($from.pos), 1, deflt ? [{type: deflt}] : undefined)) {
      can = true;
      types = deflt
        ? [
            {
              type: deflt,
              attrs: newAttributes,
            },
          ]
        : undefined;
    }

    if (can) {
      tr.split(tr.mapping.map($from.pos), 1, types);

      if (deflt && !atEnd && !$from.parentOffset && $from.parent.type !== deflt) {
        const first = tr.mapping.map($from.before());
        const $first = tr.doc.resolve(first);

        if ($from.node(-1).canReplaceWith($first.index(), $first.index() + 1, deflt)) {
          tr.setNodeMarkup(tr.mapping.map($from.before()), deflt);
        }
      }
    }
    if (keepMarks) {
      ensureMarks(state, editor.extensionManager.splittableMarks);
    }
    tr.scrollIntoView();
  }

  return true;
};

export const splitListItem = (typeOrName) => ({tr, state, dispatch, editor}) => {
  const type = getNodeType(typeOrName, state.schema);
  const {$from, $to} = state.selection;
  const node = state.selection.node;

  if ((node && node.isBlock) || $from.depth < 2 || !$from.sameParent($to)) {
    return false;
  }

  const grandParent = $from.node(-1);
  if (grandParent.type !== type) {
    return false;
  }

  const extensionAttributes = editor.extensionManager.attributes;

  if ($from.parent.content.size === 0 && $from.node(-1).childCount === $from.indexAfter(-1)) {
    if ($from.depth === 2 || $from.node(-3).type !== type || $from.index(-2) !== $from.node(-2).childCount - 1) {
      return false;
    }

    if (dispatch) {
      let wrap = Fragment.empty;
      const depthBefore = $from.index(-1) ? 1 : $from.index(-2) ? 2 : 3;

      for (let d = $from.depth - depthBefore; d >= $from.depth - 3; d -= 1) {
        wrap = Fragment.from($from.node(d).copy(wrap));
      }

      const depthAfter = $from.indexAfter(-1) < $from.node(-2).childCount ? 1 : $from.indexAfter(-2) < $from.node(-3).childCount ? 2 : 3;
      const newNextTypeAttributes = getSplittedAttributes(extensionAttributes, $from.node().type.name, $from.node().attrs);
      const nextType = type.contentMatch.defaultType?.createAndFill(newNextTypeAttributes) || undefined;

      wrap = wrap.append(Fragment.from(type.createAndFill(null, nextType) || undefined));

      const start = $from.before($from.depth - (depthBefore - 1));
      tr.replace(start, $from.after(-depthAfter), new Slice(wrap, 4 - depthBefore, 0));
      let sel = -1;

      tr.doc.nodesBetween(start, tr.doc.content.size, (n, pos) => {
        if (sel > -1) {
          return false;
        }

        if (n.isTextblock && n.content.size === 0) {
          sel = pos + 1;
        }
      });

      if (sel > -1) {
        tr.setSelection(TextSelection.near(tr.doc.resolve(sel)));
      }

      tr.scrollIntoView();
    }

    return true;
  }

  const nextType = $to.pos === $from.end() ? grandParent.contentMatchAt(0).defaultType : null;
  const newTypeAttributes = getSplittedAttributes(extensionAttributes, grandParent.type.name, grandParent.attrs);
  const newNextTypeAttributes = getSplittedAttributes(extensionAttributes, $from.node().type.name, $from.node().attrs);

  if (newTypeAttributes.id) {
    newTypeAttributes.id = getId();
  }
  if (newNextTypeAttributes.id) {
    newNextTypeAttributes.id = getId();
  }
  tr.delete($from.pos, $to.pos);

  const types = nextType
    ? [
        {type, attrs: newTypeAttributes},
        {type: nextType, attrs: newNextTypeAttributes},
      ]
    : [{type, attrs: newTypeAttributes}];

  if (!canSplit(tr.doc, $from.pos, 2)) {
    return false;
  }

  if (dispatch) {
    const {selection, storedMarks} = state;
    const {splittableMarks} = editor.extensionManager;
    const marks = storedMarks || (selection.$to.parentOffset && selection.$from.marks());

    tr.split($from.pos, 2, types).scrollIntoView();

    if (!marks || !dispatch) {
      return true;
    }

    const filteredMarks = marks.filter((mark) => splittableMarks.includes(mark.type.name));
    tr.ensureMarks(filteredMarks);
  }

  return true;
};
