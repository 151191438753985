import {createApi} from '@reduxjs/toolkit/query/react';
import axios from 'axios';
import store from '../store';
import _ from 'lodash';

const axiosBaseQuery =
  ({baseUrl} = {baseUrl: ''}) =>
    async ({url, method = 'GET', data, params, headers}) => {
      const {session} = store.getState();
      const _headers = {
        'x-inscope-org': session.currentOrg.orgId,
        ...headers,
      }
      if (!_.isNil(session.accessToken)) {
        _headers.Authorization = `Bearer ${session.accessToken}`;
      }
      try {
        const result = await axios({
          url: `${baseUrl}${url}`,
          method,
          data,
          params,
          headers: _headers,
        })
        return {data: result.data}
      } catch (axiosError) {
        const err = axiosError
        return {
          error: {
            status: err.response?.status,
            data: err.response?.data || err.message,
          },
        }
      }
    }

export const excelApiSlice = createApi({
  reducerPath: 'excelApi',
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_EXCEL_API_URL,
  }),
  endpoints: builder => ({
    getLinkedItem: builder.query({
      query: ({itemId}) => ({
        url: `/api/linking/item/${itemId}`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  endpoints,
  useGetLinkedItemQuery,
} = excelApiSlice;
