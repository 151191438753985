import React, {useEffect} from 'react'
import {Routes, Route, Navigate} from 'react-router-dom'
import {withRequiredAuthInfo} from '@propelauth/react'
import Overview from './pages/OverviewPage'
import ReportsPage from './pages/ReportsPage'
import DisclosureGuidePage from './pages/DisclosureGuidePage'
import DisclosureDetail from './components/DisclosureDetail'
import DocumentsPage from './pages/DocumentsPage'
import Document from './pages/report-workspace/Document'
import HelpPage from './pages/HelpPage'
import AccountsPage from './pages/AccountsPage'
import DepartmentsPage from './pages/DepartmentsPage'
import FSLIPage from './pages/FSLIPage';
import {ThemeProvider, createTheme} from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import {useDispatch, useSelector} from 'react-redux';
import {setOrg, setOrgConfig, reset as orgReset, setOrgs, setAccessToken} from './data/session';
import {setPeriods, reset as periodsReset} from './data/periods';
import _ from 'lodash';
import {apiSlice} from './data/api';
import DefaultLayout from './layouts/DefaultLayout';
import WorkspaceLayout from './layouts/WorkspaceLayout';
import {useLDClient, withLDConsumer} from 'launchdarkly-react-client-sdk';
import {withLogger} from './components/LoggingWrapper';
import PresentationItemsPage from './pages/PresentationItemsPage';
import AccountsMetadataPage from './pages/AccountsMetadataPage';
import DepartmentsMetadataPage from './pages/DepartmentsMetadataPage';
import {setAutoApproveReports, setReviewMode, reset as reportsReset} from './data/reports';
import * as _theme from './theme';
import {identify} from './util';

export const BLACK_100 = _theme.BLACK_100;
export const BLACK_90 = _theme.BLACK_90;
export const WHITE_100 = _theme.WHITE_100;
export const GREY_100 = _theme.GREY_100;
export const GREY_90 = _theme.GREY_90;
export const GREY_80 = _theme.GREY_80;
export const GREY_70 = _theme.GREY_70;
export const GREEN_NAV = _theme.GREEN_NAV;
export const GREEN_100 = _theme.GREEN_100;
export const GREEN_95 = _theme.GREEN_95;
export const GREEN_80 = _theme.GREEN_80;
export const GREEN_70 = _theme.GREEN_70;
export const GREEN_60 = _theme.GREEN_60;
export const GREEN_50 = _theme.GREEN_50;

export const FOOTER_HEIGHT = process.env.REACT_APP_ENVIRONMENT === 'production' ? '0rem' : '2.25rem';
export const APP_HEADER_HEIGHT = '3rem';
export const COPILOT_DRAWER_WIDTH = '21.75rem';
export const DRAWER_AND_COMPOSER_WIDTH = '28.75rem';
export const MAX_PAGE_CONTENT_WIDTH = '1224px';

const theme = createTheme();
const lightTheme = createTheme(theme, {
  palette: {
    mode: 'light',
    darkGreen: {
      main: GREEN_100,
      contrastText: WHITE_100,
    },
    colors: {
      green70: GREEN_70,
      grey90: GREY_90,
    },
    black: theme.palette.augmentColor({
      color: {
        main: BLACK_100,
      },
      name: 'black',
    }),
  },
  components: {
    MuiSwitch: {
      styleOverrides: {
        colorPrimary: {
          '&.Mui-checked': {
            // Controls checked color for the thumb
            color: GREEN_100,
          },
        },
        track: {
          // Controls default (unchecked) color for the track
          opacity: 0.7,
          backgroundColor: GREEN_100,
          '.Mui-checked.Mui-checked + &': {
            // Controls checked color for the track
            opacity: 0.7,
            backgroundColor: GREEN_100,
          },
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: BLACK_100,
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: GREEN_70,
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          height: '2.25rem',
          '&:nth-of-type(even)': {
            backgroundColor: '#EAEAEA',
          },
          '&:nth-of-type(odd)': {
            backgroundColor: WHITE_100,
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          color: BLACK_100,
          fontSize: '0.875rem',
          fontWeight: '300',
          fontStyle: 'normal',
          lineHeight: '130%',
          letterSpacing: '0.0175rem',
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        root: {
          color: GREEN_100,
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiButton: {
      defaultProps: {
        disableRipple: true,
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
        outlined: {
          borderColor: GREY_80,
          color: GREEN_100,
          '&:hover': {
            borderColor: GREEN_100,
            backgroundColor: GREEN_70,
          },
        },
      },
    },
    MuiButtonGroup: {
      styleOverrides: {
        firstButton: {
          borderColor: GREEN_95,
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          height: '2.25rem',
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: WHITE_100,
          color: BLACK_100,
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: ['Helvetica', 'Arial', 'sans-serif'].join(','),
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: '#27353A',
          color: '#FFF',
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          '&.Mui-checked': {
            color: GREEN_95,
          },
        },
      },
    },
    MuiChip: {
      variants: [
        {
          props: {variant: 'open'},
          style: {
            backgroundColor: '#F5CF7F',
          },
        },
        {
          props: {variant: 'closed'},
          style: {
            backgroundColor: '#50CDEB',
          },
        },
      ],
    },
  },
  typography: {
    color: BLACK_100,
    body1: {
      fontSize: '0.875rem',
      fontStyle: 'normal',
      fontWeight: '300',
      lineHeight: '130%',
      letterSpacing: '0.0175rem',
    },
    body2: {
      fontSize: '0.875rem',
      fontStyle: 'normal',
      lineHeight: '130%',
      fontWeight: '500',
      letterSpacing: '0.0175rem',
    },
    h1: {
      fontSize: '2rem',
      fontWeight: '700',
      lineHeight: '130%',
      letterSpacing: '0.04rem',
      fontStyle: 'normal',
    },
    h2: {
      fontSize: '1.5rem',
      fontWeight: '600',
      lineHeight: '130%',
      letterSpacing: '0.0325rem',
      fontStyle: 'normal',
    },
    h3: {
      fontSize: '1rem',
      fontWeight: '600',
      lineHeight: '130%',
      letterSpacing: '0.02rem',
      fontStyle: 'normal',
    },
    fixed: {
      fontSize: '0.875rem',
      fontStyle: 'normal',
      fontFamily: ['monospace'],
    },
  },
});

const sortOrgs = (orgs) => {
  return orgs.sort((a, b) => {
    const nameA = a.orgName.toUpperCase();
    const nameB = b.orgName.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    return 0;
  });
}

const App = withRequiredAuthInfo(({isLoggedIn, orgHelper, accessHelper, accessToken, user, flags, logger}) => {
  const dispatch = useDispatch();
  const ldClient = useLDClient();
  const {currentOrg} = useSelector((state) => state.session);
  const [trigger, result, lastPromiseInfo] = apiSlice.endpoints.getOrg.useLazyQuery({preferCacheValue: true});
  useEffect(() => {
    if (isLoggedIn) {
      const _orgs = sortOrgs(orgHelper.getOrgs());
      dispatch(setOrgs({orgs: _orgs}));
      dispatch(setOrg({org: _orgs[0]}));
      logger.debug('User and org (initial)', {user, org: _orgs[0]});
      identify(ldClient, user, _orgs[0], logger);
    }
    return () => {
      dispatch(periodsReset());
      dispatch(orgReset());
    }
  }, []);

  useEffect(() => {
    logger.debug('Setting org config', {orgConfig: result, lastPromiseInfo, currentOrg});
    dispatch(setOrgConfig({orgConfig: result.currentData}));
    dispatch(setAutoApproveReports({autoApproveReports: _.get(result, 'currentData.autoApproveReports', false)}));
  }, [result]);

  useEffect(() => {
    logger.debug('User and org (user changed)', {user, org: currentOrg});
    identify(ldClient, user, currentOrg, logger);
  }, [user]);

  useEffect(() => {
    dispatch(setAccessToken({accessToken}));
  }, [accessToken]);

  useEffect(() => {
    if (isLoggedIn) {
      const _orgs = sortOrgs(orgHelper.getOrgs());
      dispatch(setOrgs({orgs: _orgs}));
      dispatch(setOrg({org: _orgs[0]}));
      logger.debug('User and org (isLoggedIn changed)', {user, org: _orgs[0]});
      identify(ldClient, user, _orgs[0], logger);
    }
    return () => {
      dispatch(periodsReset());
      dispatch(orgReset());
    }
  }, [isLoggedIn]);
  useEffect(() => {
    if (_.isNil(currentOrg) || _.isNil(currentOrg.orgId) || _.isEmpty(currentOrg.orgId)) {
      return;
    }
    dispatch(apiSlice.util.resetApiState())
    dispatch(periodsReset());
    dispatch(reportsReset());
    dispatch(setReviewMode({reviewMode: accessHelper.isRole(currentOrg.orgId, 'InScope Report Admin')}));
    dispatch(apiSlice.endpoints.getReportingPeriods.initiate()).then(({data}) => {
      logger.debug('Reporting periods loaded', {reportingPeriods: data});
      dispatch(setPeriods({reportingPeriods: data}));
    });
    trigger(currentOrg.orgId);
    logger.debug('User and org (user changed)', {user, org: currentOrg});
    identify(ldClient, user, currentOrg, logger);
  }, [currentOrg]);

  return (
    <ThemeProvider theme={lightTheme}>
      <CssBaseline />
      <Routes>
        <Route path={'/'} element={<DefaultLayout />}>
          <Route index element={<Overview />} />
          <Route path={'support'} element={<HelpPage />} />
          <Route path={'reports'} element={<ReportsPage />} />
          <Route path={'documents'} element={<DocumentsPage />} />
          <Route path={'departments'} element={_.includes(['off', 'shadow'], flags.accountConfigurationMigration) ? <DepartmentsPage /> : <DepartmentsMetadataPage />} />
          <Route path={'fslis'} element={_.includes(['off', 'shadow'], flags.accountConfigurationMigration) ? <FSLIPage /> : <PresentationItemsPage />} />
          <Route path={'presentationitems'} element={<PresentationItemsPage />} />
          <Route path={'chartofaccounts'} element={_.includes(['off', 'shadow'], flags.accountConfigurationMigration) ? <AccountsPage /> : <AccountsMetadataPage />} />
          <Route path={'disclosure-library'} element={<DisclosureGuidePage />} />
          <Route path={'disclosure-library/:slug'} element={<DisclosureDetail />} />
        </Route>

        <Route path={'/'} element={<WorkspaceLayout />}>
          <Route path={'document'} element={<Document />} />
          <Route path={'documents/:documentId'} element={<Document />} />
        </Route>

        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </ThemeProvider>
  )
})

export default withLDConsumer()(withLogger(App));
