import {generateHTML} from '@tiptap/html';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline'
import DocumentTableNodeView from '../DocumentTable/DocumentTableNodeView';
import {LiveblocksCommentsHighlight} from '../CommentHighlight';

export function getFlag (cnode) {
  const paragraphDOM = document.querySelector(`[data-id="${cnode.attrs.id}"]`)
  if (!paragraphDOM) return null;
  const width = paragraphDOM.getBoundingClientRect().width;
  const html = generateHTML(getJsonFromDoc(cnode), getExtensions());
  const {width: wordWidth} = computedWidth(html, false);

  if (width >= wordWidth) {
    return false;
  }

  let strLength = 0;
  cnode.descendants((node, pos, parent, index) => {
    if (node.isText) {
      const nodeText = node.text;
      if (nodeText) {
        for (let i = 0; i < nodeText.length; i++) {
          const {width: wordWidth} = computedWidth(nodeText.charAt(i));
          if (strLength + wordWidth > width) {
            strLength = wordWidth;
          } else {
            strLength += wordWidth;
          }
        }
      }
    } else {
      const html = generateHTML(getJsonFromDoc(node), getExtensions());
      const {width: wordWidth} = computedWidth(html);
      if (strLength + wordWidth > width) {
        strLength = wordWidth;
      } else {
        strLength += wordWidth;
      }
    }
  });
  const space = parseFloat(window.getComputedStyle(paragraphDOM).getPropertyValue('font-size'));
  return Math.abs(strLength - width) < space;
}

function calculateNodeOverflowWidthAndPoint (node, width, splitContext) {
  let strLength = 0;
  let allHeight = 0;
  let maxHeight = 0;
  const index = 0;
  let isFlag = true;
  node.descendants((node, pos, parent, index) => {
    if (!isFlag) {
      return isFlag;
    }
    if (node.isText) {
      let isMark = false;
      if (node.marks.length) isMark = true;
      const nodeText = node.text;

      if (nodeText) {
        for (let i = 0; i < nodeText.length; i++) {
          let resource = nodeText.charAt(i);
          if (isMark && resource !== ' ') {
            const nodeJson = node.toJSON();
            nodeJson.text = resource;
            resource = generateHTML(getJsonFromDocForJson(nodeJson), getExtensions());
          }
          const {width: wordWidth, height} = computedWidth(resource);
          if (strLength + wordWidth > width) {
            allHeight += maxHeight;
            if (splitContext.isOverflow(allHeight)) {
              isFlag = false;
              return isFlag;
            }
            index = pos + i + 1;
            strLength = wordWidth;
            maxHeight = 0;
          } else {
            if (height > maxHeight) maxHeight = height;
            strLength += wordWidth;
          }
        }
      }
    } else {
      const html = generateHTML(getJsonFromDoc(node), getExtensions());
      const {width: wordWidth, height} = computedWidth(html);

      if (strLength + wordWidth > width) {
        allHeight += maxHeight;
        if (splitContext.isOverflow(allHeight)) {
          isFlag = false;
          return isFlag;
        }
        index = pos + 1;
        strLength = wordWidth;
        maxHeight = 0;
      } else {
        if (height > maxHeight) maxHeight = height;
        strLength += wordWidth;
      }
    }
  });

  return {strLength, index};
}

// Hack to remove the commentHightlight custom mark from the json before trying to
// generateHTML. It's not working otherwise and have not figured out why
function removeCustomMarkFromJson (nodeJson) {
  if (nodeJson.marks) {
    nodeJson.marks = nodeJson.marks.filter(mark => mark.type !== 'commentHighlight');
  }
  if (nodeJson.content) {
    nodeJson.content.forEach(child => removeCustomMarkFromJson(child));
  }
  return nodeJson;
}

export function getBreakPos (cnode, dom, splitContext) {
  const paragraphDOM = dom;
  if (!paragraphDOM) return null;
  const width = paragraphDOM.offsetWidth;

  const html = generateHTML(getJsonFromDoc(cnode), getExtensions());
  const {width: wordWidth} = computedWidth(html, false);
  if (width >= wordWidth) {
    return null;
  }
  const {index} = calculateNodeOverflowWidthAndPoint(cnode, width, splitContext);
  return index || null;
}

export function getJsonFromDoc (node) {
  let json = node.toJSON();
  json = removeCustomMarkFromJson(json);

  return {
    type: 'doc',
    content: [json],
  };
}

export function getJsonFromDocForJson (json) {
  const finalJson = removeCustomMarkFromJson(json);

  return {
    type: 'doc',
    content: [finalJson],
  };
}

export function getExtensions () {
  return [
    StarterKit.configure({
      history: false,
    }),
    DocumentTableNodeView,
    Underline,
    LiveblocksCommentsHighlight.configure({
      HTMLAttributes: {
        class: 'comment-highlight',
      },
    }),
  ];
}

export function getBlockHeight (node) {
  const paragraphDOM = document.querySelector(`[data-id="${node.attrs.id}"]`);
  if (paragraphDOM) {
    return paragraphDOM.offsetHeight;
  }
  return 0;
}

export class UnitConversion {
  constructor () {
    const arr = [];
    if (window.screen.deviceXDPI) {
      arr.push(window.screen.deviceXDPI);
      arr.push(window.screen.deviceYDPI);
    } else {
      const tmpNode = document.createElement('DIV');
      tmpNode.style.cssText = 'width:1in;height:1in;position:absolute;left:0px;top:0px;z-index:-99;visibility:hidden';
      document.body.appendChild(tmpNode);
      arr.push(tmpNode.offsetWidth);
      arr.push(tmpNode.offsetHeight);
      if (tmpNode && tmpNode.parentNode) {
        tmpNode.parentNode.removeChild(tmpNode);
      }
    }
    this.arrDPI = arr;
  }

  pxConversionMm (value) {
    const inch = value / this.arrDPI[0];
    const cValue = inch * 25.4;
    return Number(cValue.toFixed());
  }

  mmConversionPx (value) {
    const inch = value / 25.4;
    const cValue = inch * this.arrDPI[0];
    return Number(cValue.toFixed());
  }
}

const map = new Map();

export function computedWidth (html, cache = true) {
  if (map.has(html)) {
    return map.get(html);
  }

  const computedspan = document.getElementById('computedspan');

  if (html === ' ') {
    html = '&nbsp;';
  }

  if (computedspan) {
    computedspan.innerHTML = html;
    const width = computedspan.getBoundingClientRect().width;
    const height = computedspan.getBoundingClientRect().height;
    if (cache) {
      map.set(html, {height, width});
    }
    computedspan.innerHTML = '&nbsp;';
    return {height, width};
  }
  return 0;
}

export function getContentSpacing (dom) {
  const content = dom.querySelector('.content');
  if (dom && content) {
    const contentStyle = window.getComputedStyle(content);
    const paddingTop = contentStyle.getPropertyValue('padding-top');
    const paddingBottom = contentStyle.getPropertyValue('padding-bottom');
    const marginTop = contentStyle.getPropertyValue('margin-top');
    const marginBottom = contentStyle.getPropertyValue('margin-bottom');
    const padding = parseFloat(paddingTop) + parseFloat(paddingBottom);
    const margin = parseFloat(marginTop) + parseFloat(marginBottom);
    return padding + margin + (dom.offsetHeight - content.offsetHeight);
  }
  return 0;
}

export function getSpacing (dom) {
  const contentStyle = window.getComputedStyle(dom);
  const paddingTop = contentStyle.getPropertyValue('padding-top');
  const paddingBottom = contentStyle.getPropertyValue('padding-bottom');
  const marginTop = contentStyle.getPropertyValue('margin-top');
  const marginBottom = contentStyle.getPropertyValue('margin-bottom');
  const padding = parseFloat(paddingTop) + parseFloat(paddingBottom);
  const margin = parseFloat(marginTop) + parseFloat(marginBottom);
  return padding + margin;
}

export function getDomHeight (dom) {
  const contentStyle = window.getComputedStyle(dom);
  const paddingTop = contentStyle.getPropertyValue('padding-top');
  const paddingBottom = contentStyle.getPropertyValue('padding-bottom');
  const marginTop = contentStyle.getPropertyValue('margin-top');
  const marginBottom = contentStyle.getPropertyValue('margin-bottom');
  const padding = parseFloat(paddingTop) + parseFloat(paddingBottom);
  const margin = parseFloat(marginTop) + parseFloat(marginBottom);
  return padding + margin + dom?.offsetHeight + parseFloat(contentStyle.borderWidth);
}

export function getAbsentHtml (node) {
  const html = generateHTML(getJsonFromDoc(node), getExtensions());

  if (node.type.name === 'paragraph') {
    const computeddiv = document.getElementById('computedspan');
    if (computeddiv) {
      computeddiv.innerHTML = html;
    }
  } else {
    const computeddiv = document.getElementById('computeddiv');
    if (computeddiv) {
      computeddiv.innerHTML = html;
    }
  }

  return document.querySelector(`[data-id="${node.attrs.id}"]`)
}
