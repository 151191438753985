import React from 'react';
import PropTypes from 'prop-types';
import {Box, Skeleton} from '@mui/material';
import Loading from '../../Loading';

const TableSkeleton = ({height, isLinked}) => {
  return (
    <>
      {isLinked
        ? (
          <>
            <Skeleton
              animation={false}
              variant="rectangular"
              height={height}
              sx={{bgcolor: 'rgba(229, 247, 230, 0.50)'}}
            />
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '250px',
                height: '40px',
                borderRadius: '4px',
                bgcolor: '#fff',
                zIndex: 1,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
                boxShadow: '0px 2px 6px 0px rgba(0, 0, 0, 0.20)',
              }}
            >
              <Loading
                loading={true}
                loadingProps={{
                  size: '32',
                  speed: '1',
                  color: '#31C67D',
                }}
                message='Fetching linked data'
                typographyProps={{
                  ml: 1.5,
                  pt: 0.8,
                  fontSize: 13,
                  fontWeight: 500,
                  color: '#0F1E24',
                  marginBottom: '0px !important',
                  paddingTop: 0,
                  marginTop: 0,
                }}
                containerProps={{direction: 'row'}}
              />
            </Box>
          </>
          )
        : (
          <Skeleton
            variant="rectangular"
            height={height}
            sx={{bgcolor: 'rgba(229, 247, 230, 0.50)'}}
          />
          )
      }
    </>
  );
};

TableSkeleton.propTypes = {
  height: PropTypes.number.isRequired,
  isLinked: PropTypes.bool,
};

export default TableSkeleton;
