import {createSlice} from '@reduxjs/toolkit';
import spacetime from 'spacetime';
import _ from 'lodash';
import hash from 'object-hash';

const INITIAL_STATE = {
  documentId: null,
  jobId: null,
  asOf: null,
  items: null,
  openItems: null,
  numItems: 0,
  numOpenItems: 0,
  copilotActionError: false,
  copilotErrorMessage: null,
  copilotActionSuccess: false,
  copilotActionMessage: null,
  copilotReviewComplete: false,
}

const parseListOfDiscrepancies = (discrepancies) => {
  const _discrepancies = {};
  _.forEach(_.cloneDeep(_.filter(discrepancies, {dismissed: false})), _discrepancy => {
    // _discrepancy.id = crypto.randomUUID();
    _discrepancy.resolved = false;
    _discrepancy.type = 'discrepancies';
    const _itemDiscrepancies = {};
    _.forEach(_discrepancy.list_of_discrepancies, (_values, _key) => {
      _.forEach(_values, _item => {
        _.forEach(_item.items, _item => {
          _itemDiscrepancies[hash(_item)] = _item;
        })
      });
    })
    _discrepancy.items = _.values(_itemDiscrepancies);
    _discrepancies[_discrepancy.id] = _discrepancy;
  });
  if (process.env.NODE_ENV === 'development') {
    console.log('parseListOfDiscrepancies', _discrepancies)
  }
  return _discrepancies;
}

const parseFootingIssues = (footingIssues) => {
  const _footingIssues = {};
  _.forEach(_.cloneDeep(_.filter(footingIssues, {dismissed: false})), _footingIssue => {
    _.forEach(_footingIssue.footings, _footing => {
      const _item = {
        ..._footing,
        ..._.pick(_footingIssue, ['table_id', 'table_page_number']),
        type: 'footings',
        // id: crypto.randomUUID(),
        resolved: false,
      }
      _footingIssues[_item.id] = _.transform(_item, (result, value, key) => {
        result[key.toLowerCase()] = value;
      })
    });
  });
  if (process.env.NODE_ENV === 'development') {
    console.log('parseFootingIssues', _footingIssues)
  }
  return _footingIssues;
}

export const copilotSlice = createSlice({
  name: 'copilot',
  initialState: INITIAL_STATE,
  reducers: {
    setDocumentId: (state, action) => {
      state.documentId = action.payload.documentId;
    },
    setJobId: (state, action) => {
      state.jobId = action.payload.jobId;
    },
    setCopilotItems: (state, action) => {
      state.asOf = spacetime.now().epoch;
      state.items = _.merge(parseListOfDiscrepancies(action.payload.discrepancies.with_discrepancies), parseFootingIssues(action.payload.footingIssues));
      state.openItems = state.items;
      state.numItems = _.values(state.items).length;
      state.numOpenItems = state.numItems;
    },
    markItemDismissed: (state, action) => {
      console.log('markItemDismissed', action.payload.id, state.items[action.payload.id]);
      state.items[action.payload.id].dismissed = true;
      delete state.openItems[action.payload.id];
      state.numOpenItems = _.values(state.openItems).length;
      state.copilotActionMessage = action.payload.copilotActionMessage;
      state.copilotActionSuccess = true;
    },
    markItemReviewed: (state, action) => {
      state.items[action.payload.id].resolved = true;
      delete state.openItems[action.payload.id];
      state.numOpenItems = _.values(state.openItems).length;
    },
    showActionSuccess: (state, action) => {
      state.copilotActionSuccess = true;
      state.copilotActionMessage = _.get(action, 'payload.copilotActionMessage', null);
    },
    hideActionSuccess: (state) => {
      state.copilotActionSuccess = false;
      state.copilotActionMessage = null;
    },
    showReviewComplete: (state) => {
      state.copilotReviewComplete = true;
    },
    hideReviewComplete: (state) => {
      state.copilotReviewComplete = false;
    },
    hideActionError: (state) => {
      state.copilotActionError = false;
      state.copilotErrorMessage = null;
    },
    showActionError: (state, action) => {
      state.copilotActionError = true;
      state.copilotErrorMessage = _.get(action, 'payload.copilotErrorMessage', null);
    },
    reset: () => INITIAL_STATE,
  },
})

// Action creators are generated for each case reducer function
export const {markItemDismissed, markItemReviewed, setDocumentId, setJobId, setCopilotItems, reset, showActionSuccess, hideActionSuccess, showReviewComplete, hideReviewComplete, showActionError, hideActionError} = copilotSlice.actions;

export default copilotSlice.reducer;
