import {Extension, findChildrenInRange, findParentNode} from '@tiptap/core';
import {getFlag} from './page/Core';
import {Selection, TextSelection} from '@tiptap/pm/state';
import {ReplaceStep} from '@tiptap/pm/transform';
import {Slice} from '@tiptap/pm/model';

import * as commands from './commands';

export const KeyMapping = Extension.create({
  name: 'KeyMapping',

  addCommands () {
    return {
      ...commands,
    };
  },

  addKeyboardShortcuts () {
    const handleBackspace = () =>
      this.editor.commands.first(({commands}) => [
        () => {
          window.stepStatus = true;
          return false;
        },
        () => commands.undoInputRule(),
        // maybe convert first text block node to default node
        () =>
          commands.command(({tr}) => {
            const {selection, doc} = tr;
            const {empty, $anchor} = selection;
            const {pos, parent} = $anchor;
            const isAtStart = Selection.atStart(doc).from === pos;
            if (!empty || !isAtStart || !parent.type.isTextblock || parent.textContent.length) {
              return false;
            }
            return commands.clearNodes();
          }),
        () => preventTableDeletion(commands),
        () => deleteSelection(commands),
        () => commands.joinBackward(),
        () => commands.selectNodeBackward(),
        () =>
          commands.command(({tr}) => {
            // Enter this branch when all default system operations fail
            const {selection, doc} = tr;
            const {$anchor} = selection;
            const {pos} = $anchor;

            // Do not process if there is only one page
            if (doc.childCount === 1) return false;

            // Find the current page
            const pageNode = findParentNode((node) => node.type.name === 'page')(selection);
            if (pageNode) {
              const curBlock = findParentNode((node) => node.type.name.endsWith('Extend'))(selection);
              // If the cursor is at the first position of the current page
              const isAtStart = pageNode.start + Selection.atStart(pageNode.node).from === pos;
              if (isAtStart && pageNode.node.attrs.pageNumber !== 1) {
                const vm = TextSelection.create(doc, pos - 20, pos - 20);
                const beforePageNode = findParentNode((node) => node.type.name === 'page')(vm);
                // Find the previous page, get the last point, and set the cursor to select
                if (beforePageNode) {
                  const pos1 = Selection.atEnd(beforePageNode.node).from + beforePageNode.start;
                  // EXTEND is an extension type that can be deleted and merged
                  const selection1 = TextSelection.create(doc, pos1, pos1);
                  if (curBlock) {
                    const parent = selection1.$anchor.parent;
                    if (getFlag(parent)) {
                      tr.setSelection(selection1);
                    } else {
                      tr.step(new ReplaceStep(pos1, pos, Slice.empty));
                    }
                    return true;
                  }
                  tr.setSelection(selection1);
                }
                return true;
              }
            }
            return false;
          }),
      ]);

    const handleDelete = () =>
      this.editor.commands.first(({commands}) => [
        () => deleteSelection(commands),
        () =>
          commands.command(({tr}) => {
            const {selection} = tr;
            const {$anchor} = selection;
            const currentNode = $anchor.node();
            const blockNode = findParentNode((node) => node.type.name === 'Node')(selection);
            if (blockNode) {
              const isBlockStart = blockNode.start + Selection.atStart(blockNode.node).from === $anchor.pos;
              if (isBlockStart && blockNode.node.childCount === 1) {
                if (currentNode.content.size === 0) {
                  return true;
                }
              }
            }
            return commands.joinForward();
          }),
        () => {
          const a = commands.selectNodeForward();
          return a;
        },
        () =>
          commands.command(({tr}) => {
            // Enter this branch when all default system operations fail
            const {selection, doc} = tr;
            const {$anchor} = selection;
            const {pos} = $anchor;

            // Do not process if there is only one page
            if (doc.childCount === 1) return false;

            // Find the current page
            const pageNode = findParentNode((node) => node.type.name === 'page')(selection);
            if (pageNode) {
              // If the cursor is at the last position of the current page
              const isAtEnd = pageNode.start + Selection.atEnd(pageNode.node).from === pos;
              if (isAtEnd) {
                const vm = TextSelection.create(doc, pos + 6, pos + 6);
                const afterPageNode = findParentNode((node) => node.type.name === 'page')(vm);
                // Find the previous page, get the last point, and set the cursor to select
                if (afterPageNode) {
                  const pos1 = Selection.atStart(afterPageNode.node).from + afterPageNode.start;
                  // EXTEND is an extension type that can be deleted and merged
                  const selection1 = TextSelection.create(doc, pos1, pos1);
                  const curBlock = findParentNode((node) => node.type.name.endsWith('Extend'))(selection1);
                  if (curBlock) {
                    tr.step(new ReplaceStep(pos, pos1, Slice.empty));
                    return true;
                  }
                }
              }
            }
            return false;
          }),
      ]);

    return {
      Backspace: handleBackspace,
      Delete: handleDelete,
    };
  },
});

const deleteSelection = (commands) => {
  return commands.command(({tr}) => {
    const {selection, doc} = tr;
    const nodesInChangedRanges = findChildrenInRange(
      doc,
      {
        from: selection.from,
        to: selection.to,
      },
      (node) => node.type.name === 'Node',
    );
    for (let i = 0; i < nodesInChangedRanges.length; i++) {
      const node = nodesInChangedRanges[i];
      const endPos = node.pos + node.node.nodeSize;
      if (selection.from < node.pos || selection.to > endPos) {
        return true;
      }
    }
    return commands.deleteSelection();
  });
};

const preventTableDeletion = (commands) => {
  return commands.command(({tr}) => {
    const {selection} = tr;
    const {$anchor} = selection;
    const nodeBefore = $anchor.nodeBefore;
    const nodeAfter = $anchor.nodeAfter;

    if (
      (nodeBefore && nodeBefore.type.name === 'table') ||
      (nodeAfter && nodeAfter.type.name === 'table')
    ) {
      return true; // Prevent deletion
    }
    return false;
  });
};
