import React, {useEffect, useState, forwardRef} from 'react'
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import {connect} from 'react-redux';
import Box from '@mui/material/Box';
import {withRequiredAuthInfo} from '@propelauth/react';
import {GREEN_70} from '../App';
import _ from 'lodash';
import {withLogger} from '../components/LoggingWrapper';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {TableVirtuoso} from 'react-virtuoso';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import {Alert, FormControlLabel, FormGroup, Switch} from '@mui/material';
import {useGetDepartmentsQuery, useGetPresentationItemsQuery} from '../data/api';
import CompanyConfigTable from '../components/CompanyConfigTable';
import CommonPageWrapper from '../components/CommonPageWrapper';
import AppPage from './AppPage';
import {isTruthy} from '../util';

const filterActiveOnly = (departments, activeOnly) => {
  if (activeOnly) {
    return _.filter(departments, _d => !isTruthy(_d.isinactive));
  } else {
    return departments;
  }
}

class DepartmentsMetadataPage extends AppPage {
  getPageTitle = () => 'Departments';
  renderBodyContent = () => <DepartmentsMetadataPageComponent session={this.props.session} accessToken={this.props.accessToken} />;
}

const DepartmentsMetadataPageComponent = ({session}) => {
  const {currentOrg} = session;
  const [activeOnly, setActiveOnly] = useState(true);
  const [augmentedDepartments, setAugmentedDepartments] = useState([]);
  const [filteredDepartments, setFilteredDepartments] = useState([]);

  const {data: departments, isFetching: isDepartmentsFetching, isSuccess: isDepartmentsSuccess, refetch: refetchDepartments, isError: isDepartmentsError} = useGetDepartmentsQuery();
  const {data: plis, isFetching: isPLIFetching, isSuccess: isPLIsSuccess, refetch: refectPLIs, isError: isPLIError} = useGetPresentationItemsQuery();

  useEffect(() => {
    let _departments = departments;
    if (isDepartmentsSuccess && isPLIsSuccess && !_.isNil(departments)) {
      _departments = _.map(departments, _department => {
        let _fsliName = '';
        const _fsli = _.find(plis, {id: _department.pliMapping});
        if (!_.isNil(_fsli)) {
          _fsliName = _fsli.name;
        }
        return {
          ..._department,
          fsliName: _fsliName,
        }
      });
    }
    setAugmentedDepartments(_departments);
    setFilteredDepartments(filterActiveOnly(_departments, activeOnly));
  }, [departments, plis]);

  useEffect(() => {
    refetchDepartments();
    refectPLIs();
  }, [currentOrg]);

  useEffect(() => {
    setFilteredDepartments(filterActiveOnly(augmentedDepartments, activeOnly));
  }, [augmentedDepartments, activeOnly]);

  const VTCScroller = forwardRef((props, ref) => (
    <TableContainer {...props} ref={ref} />
  ));
  VTCScroller.displayName = 'Scroller';
  const VTCTableBody = forwardRef((props, ref) => <TableBody {...props} ref={ref} />);
  VTCTableBody.displayName = 'TableBody';
  const VTCTableRow = ({item: _item, ...props}) => <TableRow {...props} />;
  VTCTableRow.propTypes = {
    item: PropTypes.any.isRequired,
  };

  const VirtuosoTableComponents = {
    displayName: 'VirtuosoTableComponents',
    Scroller: VTCScroller,
    Table: (props) => (
      <Table {...props} sx={{borderCollapse: 'separate', tableLayout: 'fixed'}} />
    ),
    TableHead,
    TableRow: VTCTableRow,
    TableBody: VTCTableBody,
  };

  const fixedHeaderContent = () => {
    return (
      <TableRow>
        <TableCell
          variant={'head'}
          align={'center'}
          datatestid={'tableHeader-active'}
          sx={{width: 100, borderBottom: 1}}
        >
          <Typography variant={'body2'}>Active?</Typography>
        </TableCell>
        <TableCell
          variant={'head'}
          align={'left'}
          datatestid={'tableHeader-name'}
          sx={{
            borderBottom: 1,
          }}
        >
          <Typography variant={'body2'}>Department Name</Typography>
        </TableCell>
        <TableCell
          variant={'head'}
          align={'left'}
          datatestid={'tableHeader-name'}
          sx={{
            borderBottom: 1,
          }}
        >
          <Typography variant={'body2'}>FSLI Mapping</Typography>
        </TableCell>
      </TableRow>
    );
  }

  const rowContent = (_index, row) => {
    return (
      <React.Fragment>
        <TableCell align={'center'} datatestid={`tableRow-active-${_index}`}>
          {!isTruthy(row.isinactive) ? <TaskAltIcon /> : <RadioButtonUncheckedIcon />}
        </TableCell>
        <TableCell datatestid={`tableRow-name-${_index}`}>
          <Typography>{row.name}</Typography>
        </TableCell>
        <TableCell datatestid={`tableRow-fsliName-${_index}`}>
          <Typography>{_.upperFirst(row.fsliName)}</Typography>
        </TableCell>
      </React.Fragment>
    );
  }

  return (
    <CommonPageWrapper>
      {(isPLIError) && (
        <Box maxWidth={'45rem'} minWidth={'45rem'} alignSelf={'center'} marginBottom={'1.5rem'}>
          <Alert severity="error">There was an error loading the FSLI data. Don&apos;t worry, we&apos;re on it!</Alert>
        </Box>
      )}
      <CompanyConfigTable
        hasError={isDepartmentsError}
        errorMessage={"There was an error loading your data. We're on it!"}
        loading={isDepartmentsFetching || isPLIFetching}
        screen={'Departments'}
        success={isDepartmentsSuccess}
        hasData={!_.isEmpty(departments)}>
        <Box width={'100%'} justifySelf={'flex-end'} datatestid={'departments-box-activeToggle'} paddingTop={'1.5rem'} paddingRight={'1.5rem'} sx={{backgroundColor: GREEN_70}}>
          <FormGroup>
            <FormControlLabel checked={activeOnly} onChange={() => setActiveOnly(!activeOnly)} control={<Switch />} label={'Show Active only?'} labelPlacement={'start'} />
          </FormGroup>
        </Box>
        <TableVirtuoso
          datatestid={'departments-box-table'}
          data={filteredDepartments}
          components={VirtuosoTableComponents}
          fixedHeaderContent={fixedHeaderContent}
          itemContent={rowContent}
        />
      </CompanyConfigTable>
    </CommonPageWrapper>
  )
}

DepartmentsMetadataPageComponent.propTypes = {
  accessToken: PropTypes.string.isRequired,
  session: PropTypes.objectOf({
    currentOrg: PropTypes.objectOf({
      orgId: PropTypes.string.isRequired,
    }),
  }),
}

const mapStateToProps = (state) => {
  return {
    session: state.session,
  }
}

export default connect(mapStateToProps)(withRequiredAuthInfo(withLogger(DepartmentsMetadataPage)));
