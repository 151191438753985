export class PageState {
  constructor (bodyOptions, deleting, inserting, checkNode, splitPage) {
    this.bodyOptions = bodyOptions;
    this.deleting = deleting;
    this.inserting = inserting;
    this.checkNode = checkNode;
    this.splitPage = splitPage;
  }

  transform (tr) {
    const splitPage = tr.getMeta('splitPage') || false;
    const checkNode = tr.getMeta('checkNode') || false;
    const deleting = tr.getMeta('deleting') || false;
    const inserting = tr.getMeta('inserting') || false;

    return new PageState(this.bodyOptions, deleting, inserting, checkNode, splitPage);
  }
}
