import Handsontable from 'handsontable';
import {textRenderer, numericRenderer} from 'handsontable/renderers';
import {calcRowHeight} from './DocumentTable';

const DEFAULT_FONT_SIZE = 11;
const DEFAULT_LINE_HEIGHT = 13;
const MIN_FONT_SIZE = 8;
const MIN_LINE_HEIGHT = 11;
const DEFAULT_CELL_PADDING = 3;
const MIN_CELL_PADDING = 2;

let cachedTabWidth = null;

// Custom editor documentation
// https://handsontable.com/docs/react-data-grid/cell-editor/#how-to-create-a-custom-editor
class HeaderCellEditor extends Handsontable.editors.TextEditor {
  // Custom header cell editor implementation
  createElements () {
    super.createElements();

    // use text area to allow for newlines
    this.TEXTAREA = document.createElement('textarea');
    this.TEXTAREA.setAttribute('data-hot-input', true);
    this.TEXTAREA.className = 'handsontableInput';
    this.textareaStyle = this.TEXTAREA.style;
    this.textareaStyle.textAlign = 'center';
    this.textareaStyle.fontWeight = 'bold';

    this.TEXTAREA_PARENT.innerText = '';
    this.TEXTAREA_PARENT.appendChild(this.TEXTAREA);
  }
}

class ValueCellEditor extends Handsontable.editors.NumericEditor {
  // Custom value cell editor implementation
  createElements () {
    super.createElements();

    this.TEXTAREA = document.createElement('input');
    this.TEXTAREA.setAttribute('data-hot-input', true);
    this.TEXTAREA.className = 'handsontableInput';
    this.textareaStyle = this.TEXTAREA.style;
    this.textareaStyle.textAlign = 'right';
    this.textareaStyle.verticalAlign = 'middle';
    this.textareaStyle.paddingRight = '12px';

    this.TEXTAREA_PARENT.innerText = '';
    this.TEXTAREA_PARENT.appendChild(this.TEXTAREA);
  }
}

class LabelCellEditor extends Handsontable.editors.TextEditor {
  // Custom label cell editor implementation
  prepare (row, col, prop, td, originalValue, cellProperties) {
    super.prepare(row, col, prop, td, originalValue, cellProperties);

    // Add event listener for the Tab key press
    this.TEXTAREA.addEventListener('keydown', (event) => {
      if (event.keyCode === 9) {
        event.stopImmediatePropagation();
        event.preventDefault();

        // Insert tab at the current cursor position
        const caretPosition = this.TEXTAREA.selectionStart;
        const value = this.TEXTAREA.value;
        this.TEXTAREA.value = value.slice(0, caretPosition) + '\t' + value.slice(caretPosition);

        const newCaretPosition = caretPosition + 1; // Move cursor 1 position to the right
        this.TEXTAREA.selectionStart = newCaretPosition;
        this.TEXTAREA.selectionEnd = newCaretPosition;
      }
    });
  }

  createElements () {
    super.createElements();

    this.TEXTAREA = document.createElement('input');
    this.TEXTAREA.setAttribute('placeholder', 'Press tab to indent');
    this.TEXTAREA.setAttribute('data-hot-input', true);
    this.TEXTAREA.className = 'handsontableInput';
    this.textareaStyle = this.TEXTAREA.style;
    this.textareaStyle.paddingLeft = '12px';

    this.TEXTAREA_PARENT.innerText = '';
    this.TEXTAREA_PARENT.appendChild(this.TEXTAREA);
  }
}

const _calcCellPadding = multiplier => {
  let _padding = Math.floor(DEFAULT_CELL_PADDING * multiplier);
  if (_padding < MIN_CELL_PADDING) {
    _padding = MIN_CELL_PADDING;
  }

  return _padding;
}

const _calcFontSize = multiplier => {
  let _fontSize = Math.floor(DEFAULT_FONT_SIZE * multiplier);
  if (_fontSize < MIN_FONT_SIZE) {
    _fontSize = MIN_FONT_SIZE;
  }

  return _fontSize;
}

const _calcLineHeight = multiplier => {
  let _lineSpacing = Math.floor(DEFAULT_LINE_HEIGHT * multiplier);
  if (_lineSpacing < MIN_LINE_HEIGHT) {
    _lineSpacing = MIN_LINE_HEIGHT;
  }

  return _lineSpacing;
}

const _getCachedTabWidth = () => {
  if (cachedTabWidth === null) {
    const tabSpan = document.createElement('span');
    tabSpan.textContent = '\t';
    tabSpan.style.visibility = 'hidden';
    tabSpan.style.position = 'absolute';
    tabSpan.style.whiteSpace = 'pre';
    document.body.appendChild(tabSpan);
    const scalingFactor = 0.7;
    cachedTabWidth = (tabSpan.offsetWidth * scalingFactor) + 2;
    document.body.removeChild(tabSpan);
  }
  return cachedTabWidth;
}

// args: [instance, td, row, col, prop, value, cellProperties]
const headerCellRenderer = (...args) => {
  const [, td, row, col, , , cellProperties] = args;
  textRenderer.apply(this, args);
  applyCommonCellStyles(td, row, cellProperties);
  td.style.paddingBottom = `${_calcCellPadding(cellProperties.multiplier || 1)}px`;
  td.style.paddingTop = `${_calcCellPadding(cellProperties.multiplier || 1)}px`;
  td.style.backgroundColor = '#fff';
  td.style.verticalAlign = 'bottom';

  if (cellProperties.cleared) {
    return;
  }

  td.style.fontWeight = 'bold';

  if (row === 1 && (col % 2 !== 0)) {
    td.style.borderBottom = '1px solid black';
  }
}

const labelHeaderCellRenderer = (...args) => {
  const [, td, row, , , , cellProperties] = args;
  textRenderer.apply(this, args);
  applyCommonCellStyles(td, row, cellProperties);
  td.style.paddingLeft = `${_calcCellPadding(cellProperties.multipler || 1) * 2.4}px`;
  td.style.backgroundColor = '#fff';
  td.style.verticalAlign = 'bottom';

  if (cellProperties.cleared) {
    return;
  }

  td.style.fontWeight = 'bold';
  td.style.backgroundColor = '#fff';
  td.style.paddingLeft = `${_calcCellPadding(cellProperties.multiplier || 1) * 2.4}px`;
}

const valueCellRenderer = (...args) => {
  const [, td, row, , , value, cellProperties] = args;
  numericRenderer.apply(this, args);
  applyCommonCellStyles(td, row, cellProperties);

  const cleanValue = typeof value === 'string' ? value.replace(/,/g, '') : value;

  let displayValue;
  if (cleanValue === null || cleanValue === '') {
    displayValue = cellProperties.placeholder || '';
  } else if (!isNaN(cleanValue)) {
    displayValue = formatNumericValue(cleanValue, cellProperties.format === 'percent');
  } else {
    displayValue = value;
  }

  applyCellFormat(td, displayValue, cellProperties);
}

const labelCellRenderer = (...args) => {
  const [, td, row, , , value, cellProperties] = args;
  textRenderer.apply(this, args);
  applyCommonCellStyles(td, row, cellProperties);

  td.style.whiteSpace = 'normal';

  const leadingTabs = ((value && value.match(/^\t*/)) || [''])[0].length;

  let paddingLeft;
  if (leadingTabs > 0) {
    paddingLeft = leadingTabs * _getCachedTabWidth();
  } else {
    paddingLeft = _calcCellPadding(cellProperties.multiplier || 1) * 2.4;
  }

  td.style.paddingLeft = `${paddingLeft}px`;
  td.style.paddingTop = `${_calcCellPadding(cellProperties.multiplier || 1)}px`;
  td.style.paddingBottom = `${_calcCellPadding(cellProperties.multiplier || 1)}px`;
};

const paddingCellRenderer = (...args) => {
  const [, td, row, , , , cellProperties] = args;
  applyCommonCellStyles(td, row, cellProperties);
}

const applyCommonCellStyles = (td, row, cellProperties) => {
  const baseStyles = {
    border: '0',
    fontSize: `${_calcFontSize(cellProperties.multiplier || 1)}px`,
    lineHeight: `${_calcLineHeight(cellProperties.multiplier || 1)}px`,
    height: `${calcRowHeight(cellProperties.multiplier || 1)}px`,
    backgroundColor: row % 2 === 0 ? '#E5F7E6' : '',
  };

  const conditionalStyles = {
    borderTop: cellProperties.singleBorderTop ? '1px solid black' : (cellProperties.doubleBorderTop ? '3px double black' : 'none'),
    borderBottom: cellProperties.singleBorderBottom ? '1px solid black' : (cellProperties.doubleBorderBottom ? '3px double black' : 'none'),
  };

  Object.assign(td.style, baseStyles, conditionalStyles);
};

const applyCellFormat = (td, displayValue, cellProperties) => {
  const cellFormat = cellProperties.format;
  const hasBorder = cellProperties.doubleBorderTop || cellProperties.doubleBorderBottom;

  td.style.verticalAlign = 'bottom';
  td.style.paddingTop = `${_calcCellPadding(cellProperties.multiplier || 1)}px`;
  td.style.paddingBottom = `${_calcCellPadding(cellProperties.multiplier || 1)}px`;
  td.style.fontSize = `${_calcFontSize(cellProperties.multiplier || 1)}px`;
  td.style.lineHeight = `${_calcLineHeight(cellProperties.multiplier || 1)}px`;

  if (cellFormat === 'dollar') {
    td.style.position = 'relative';
    if (hasBorder) {
      td.innerHTML = `<span class="dollar-sign with-border" style="left: ${_calcCellPadding(cellProperties.multiplier || 1) * 2.4}px; bottom: ${_calcCellPadding(cellProperties.multiplier || 1)}px">$</span>${displayValue}`
    } else {
      td.innerHTML = `<span class="dollar-sign" style="left: ${_calcCellPadding(cellProperties.multiplier || 1) * 2.4}px; bottom: ${_calcCellPadding(cellProperties.multiplier || 1)}px">$</span>${displayValue}`
    }
  } else if (cellFormat === 'percent') {
    td.style.position = 'relative';
    td.style.paddingRight = `${_calcCellPadding(cellProperties.multiplier || 1) * 4.5}px`;
    if (hasBorder) {
      td.innerHTML = `${displayValue}<span class="percent-sign with-border" style="right: ${_calcCellPadding(cellProperties.multiplier || 1) * 0.8}px; bottom: ${_calcCellPadding(cellProperties.multiplier || 1)}px">%</span>`;
    } else {
      td.innerHTML = `${displayValue}<span class="percent-sign" style="right: ${_calcCellPadding(cellProperties.multiplier || 1) * 0.8}px; bottom: ${_calcCellPadding(cellProperties.multiplier || 1)}px">%</span>`;
    }

    td.classList.add('percent-cell');
  } else {
    td.innerHTML = displayValue;
  }
}

const formatNumericValue = (value, isPercent) => {
  if (parseFloat(value) === 0 && !isPercent) { return '-'; }

  const isNegative = value < 0;
  const absoluteValue = isNegative ? Math.abs(value) : value;

  const decimalPlaces = value.includes('.') ? value.split('.')[1].length : 0;

  const formattedValue = new Intl.NumberFormat('en-US', {
    style: 'decimal',
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces,
  }).format(absoluteValue);

  return isNegative ? `(${formattedValue})` : formattedValue;
}

Handsontable.cellTypes.registerCellType('headerCell', {
  editor: HeaderCellEditor,
  renderer: headerCellRenderer,
  validator: Handsontable.validators.TextValidator,
  className: 'htCenter htBottom',
});

Handsontable.cellTypes.registerCellType('labelHeaderCell', {
  editor: LabelCellEditor,
  renderer: labelHeaderCellRenderer,
  validator: Handsontable.validators.TextValidator,
  trimWhitespace: false,
});

Handsontable.cellTypes.registerCellType('valueCell', {
  editor: ValueCellEditor,
  renderer: valueCellRenderer,
  validator: Handsontable.validators.TextValidator,
  allowInvalid: true,
  className: 'htRight htBottom',
});

Handsontable.cellTypes.registerCellType('labelCell', {
  editor: LabelCellEditor,
  renderer: labelCellRenderer,
  validator: Handsontable.validators.TextValidator,
  allowInvalid: true,
  trimWhitespace: false,
});

Handsontable.cellTypes.registerCellType('paddingCell', {
  editor: undefined,
  renderer: paddingCellRenderer,
  validator: Handsontable.validators.TextValidator,
  readOnly: true,
  disableVisualSelection: true,
});
